import { useModal } from "@/components/UI/Modal/useModal";
import { useOptionsStoreActions } from "@/hooks/store/useOptionsStore";
import { onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useInventoriesStore } from "@/store/inventories";

export const useNewSpotDialog = () => {
  const { close } = useModal();
  const { editedInventory } = storeToRefs(useInventoriesStore());
  const { lasersOptions, loadLasersOptions } = useOptionsStoreActions();
  const spot = {
    printer_id: null,
    spot: 0,
    printer: { name: null },
  };
  onMounted(() => {
    loadLasersOptions();
  });
  const cancel = () => {
    close(null);
  };
  const create = () => {
    const existingSpot = editedInventory.value?.inventory_spots.find(
      (inventorySpot) => inventorySpot.printer_id === spot.printer_id
    );
    if (existingSpot) {
      existingSpot.spot += spot.spot;
    } else {
      // eslint-disable-next-line no-underscore-dangle
      const foundLaser = lasersOptions.value?.find((laser) => laser.__original === spot.printer_id);
      if (foundLaser) {
        spot.printer.name = foundLaser.label;
      }
      editedInventory.value?.inventory_spots.push(spot);
    }
    close(null);
  };
  return { lasersOptions, spot, create, cancel };
};
