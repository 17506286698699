import { createToast, withProps, clearToasts } from "mosha-vue-toastify";
import ToastInner from "@/components/UI/ToastInner/ToastInner.vue";

const useToast = () => {
  /*
  Opens a Toast component

  * @param {string} message - The message to display
  * @param {string} type - The type of toast to display. Variants: 'info', 'danger', 'warning', 'success', 'default'; Default: "default"
  * @param {number} timeout - The timeout of display toast in ms. Default: -1, it means that toast isn't disappear until user clicks close (x) button.
  * @param {boolean} showButton - Show close all notifications button. Default: true
  * @param {object} rest - The rest of options to pass to Toast component
  * @returns {void}
 */
  function openToast(message, type = "default", timeout = 5000, showButton = true, ...rest) {
    createToast(withProps(ToastInner, { title: message, type, showButton }), {
      type,
      timeout,
      ...rest,
    });
  }

  function clearAllToast() {
    clearToasts();
  }

  return { openToast, clearAllToast };
};

export default useToast;
