import { storeToRefs } from "pinia";
import { useProductionStore } from "@/store/production";
import { getProductionApi } from "@/api/production";
import { getPrintersApi } from "@/api/options";
import { reduceInventories } from "@/utils/reduceInventories";
import sortBy from "@/utils/sortBy";

function enhancedOrdersDue(stats) {
  const dues = [];
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in stats) {
    const due = {
      key,
      value: stats[key],
    };
    dues.push(due);
  }
  return dues;
}

export const useProductionStoreActions = () => {
  const {
    initRegularItems,
    regularItems,
    regularItemsInventories,
    regularItemsSellers,
    initPriorityItems,
    priorityItems,
    priorityItemsSellers,
    priorityItemsInventories,
    ordersDue,
    printers,
    loading,
    currentInventories,
    currentSellers,
    lastTab,
  } = storeToRefs(useProductionStore());

  const loadProduction = (params) => {
    loading.value = true;
    return getProductionApi(params)
      .then((response) => {
        const {
          regular,
          priority,
          ordersDue: _ordersDue,
          ordersGroupCount,
          nextDayDeliveryGroupIds,
          sellerOrdersCount,
        } = response.data;

        regularItemsSellers.value = sortBy(sellerOrdersCount.regular, "count");
        regularItemsInventories.value = sortBy(
          reduceInventories(sellerOrdersCount.regular),
          "count"
        );

        initRegularItems.value = regular.map((item) => {
          return {
            ...item,
            order: {
              ...item.order,
              count: ordersGroupCount[item.order_group_id],
              nextDayDelivery: nextDayDeliveryGroupIds.includes(item.order_group_id),
            },
          };
        });

        regularItems.value = initRegularItems.value;

        priorityItemsSellers.value = sortBy(sellerOrdersCount.priority, "count");
        priorityItemsInventories.value = sortBy(
          reduceInventories(sellerOrdersCount.priority),
          "count"
        );

        initPriorityItems.value = priority.map((item) => {
          return {
            ...item,
            order: {
              ...item.order,
              count: ordersGroupCount[item.order_group_id.toString()],
              nextDayDelivery: nextDayDeliveryGroupIds.includes(item.order_group_id),
            },
          };
        });

        priorityItems.value = initPriorityItems.value;

        ordersDue.value = enhancedOrdersDue(_ordersDue);
        return response;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const ifSellerAndInventory = (sellers, inventories, init) => {
    if (sellers.length === 0 && inventories.length === 0) {
      return init;
    }

    let items = init;

    if (sellers.length > 0) {
      items = items.filter((item) => sellers.includes(item.order.order_group.seller.id));
    }

    if (inventories.length > 0) {
      items = items.filter((item) => inventories.includes(item.inventories_order.inventory.id));
    }

    return items;
  };

  const changeAfterTab = (sellers, inventories, tab) => {
    currentSellers.value = [];
    currentInventories.value = [];
    switch (tab) {
      case 1:
        regularItems.value = initRegularItems.value;
        break;
      case 2:
        priorityItems.value = initPriorityItems.value;
        break;
      default:
        break;
    }
  };

  const filterOrders = (sellers, inventories, tab) => {
    if (tab === 1) {
      regularItems.value = ifSellerAndInventory(sellers, inventories, initRegularItems.value);
      return;
    }

    priorityItems.value = ifSellerAndInventory(sellers, inventories, initPriorityItems.value);
  };

  const loadPrinters = () => {
    return getPrintersApi()
      .then((response) => {
        printers.value = response.data;
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  const clearFilters = () => {
    currentInventories.value = [];
    currentSellers.value = [];
  };

  return {
    loadProduction,
    loadPrinters,
    filterOrders,
    changeAfterTab,
    clearFilters,
    ordersDue,
    regularItems,
    regularItemsInventories,
    regularItemsSellers,
    priorityItems,
    priorityItemsSellers,
    priorityItemsInventories,
    printers,
    loading,
    currentInventories,
    currentSellers,
    lastTab,
  };
};
