export const removeEmpty = (obj) => {
  return Object.entries(obj)
    .filter(([, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? removeEmpty(v) : v }), {});
};

export const removeEmptyString = (obj) => {
  return Object.entries(obj)
    .filter(([, v]) => v !== "" && v !== null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? removeEmptyString(v) : v }), {});
};
