import request from "@/utils/request";

export const authApi = (data) =>
  request({
    url: "/api/auth",
    method: "post",
    data,
  });

export const getUserDetailsApi = () =>
  request({
    url: "/api/V2/users/me",
    method: "get",
  });

export const loginApi = (data) =>
  request({
    url: "/api/V2/login",
    method: "post",
    data,
  });

export const verifyApi = (data) =>
  request({
    url: "/api/V2/verify",
    method: "post",
    data,
  });

export const logoutApi = () =>
  request({
    url: "/api/V2/logout",
    method: "post",
  });

export const redirectApi = (data) =>
  request({
    url: "/api/V2/redirect",
    method: "post",
    data,
  });
