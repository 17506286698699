import request from "@/utils/request";

// We use these requests for select forms

export const getOrderStatusesApi = () =>
  request({
    url: "/api/V2/orders/statuses",
    method: "get",
  });

export const getDesignersApi = () =>
  request({
    url: "/api/V2/users/designers",
    method: "get",
  });

export const getCountriesApi = () =>
  request({
    url: "/api/V2/orders/countries",
    method: "get",
  });

export const getProductsApi = () =>
  request({
    url: "/api/V2/orders/products",
    method: "get",
  });

export const getPrintersApi = () =>
  request({
    url: "/api/V2/printers?short",
    method: "get",
  });

export const getInventoriesApi = () =>
  request({
    url: "/api/V2/orders/inventories",
    method: "get",
  });

export const getSellersApi = () =>
  request({
    url: "/api/V2/sellers?short",
    method: "get",
  });

export const getStoresApi = () =>
  request({
    url: "/api/V2/stores?short",
  });

export const getOrdersByGroups = (groups) =>
  request({
    url: `/api/V2/orders/groups/${groups}`,
    method: "get",
  });

export const getShippingServicesApi = () =>
  request({
    url: "/api/V2/shipping-services",
    method: "get",
  });
export const getArtistTeam = () =>
  request({
    url: "/api/V2/users/team",
    method: "get",
  });
