<template>
  <form @submit.prevent="ship">
    <div class="modal_inputs">
      <Text :text="message" />
    </div>
    <div class="modal_actions">
      <Button secondary-button text="Cancel" @click="cancel"></Button>
      <Button text="Submit" type="submit"></Button>
    </div>
  </form>
</template>

<script setup>
import { toRefs } from "vue";
import Button from "@/components/UI/Form/Button/Button.vue";
import { useModal } from "@/components/UI/Modal/useModal";
import Text from "@/components/UI/Typography/Text.vue";
import { useRouter, useRoute } from "vue-router";
import { routeAfterCheck, transformOrder } from "@/utils/shippingUtils";

const { close } = useModal();

const router = useRouter();
const route = useRoute();

const props = defineProps({
  message: String,
  scanned: String,
  binning: Object,
  checked: Boolean,
  gid: Array,
});

const { message, scanned, binning, checked, gid } = toRefs(props);

const cancel = () => {
  close(null);
};

const ship = () => {
  const shippingPath = "/crm/shipping/";
  const gatherBinningPath = "/crm/gather-binning/";
  const shippingLabel = `/files/plabel/${gid.value.join("-")}.pdf`;
  const currentParam = transformOrder(scanned.value);
  const scans = scanned.value.endsWith(",")
    ? scanned.value
        .slice(0, -1)
        .split(",")
        .filter((item) => item !== "0")
        .join(",")
    : scanned.value;
  if (binning.value.binning !== 0) {
    routeAfterCheck(gatherBinningPath, currentParam, scans, route.path, router);
  } else {
    if (!checked.value && gid.value.length > 0) {
      window.open(shippingLabel);
    }
    routeAfterCheck(shippingPath, currentParam, scans, route.path, router);
  }
  close(null);
};
</script>
