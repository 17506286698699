<template>
  <path
    d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
    fill="#A41717"
  />
  <path
    d="M12 19.5C12.8284 19.5 13.5 18.8284 13.5 18C13.5 17.1716 12.8284 16.5 12 16.5C11.1716 16.5 10.5 17.1716 10.5 18C10.5 18.8284 11.1716 19.5 12 19.5Z"
    fill="#EBDADA"
  />
  <path d="M10.5 4.5H13.5V15H10.5V4.5Z" fill="#EBDADA" />
</template>
