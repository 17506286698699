<template>
  <mask
    id="mask0_2580_16509"
    height="24"
    maskUnits="userSpaceOnUse"
    style="mask-type: alpha"
    width="24"
    x="0"
    y="0"
  >
    <rect fill="#302D5C" height="24" rx="12" width="24" />
  </mask>
  <g mask="url(#mask0_2580_16509)">
    <rect fill="#302D5C" height="24" rx="12" width="24" />
    <mask
      id="mask1_2580_16509"
      height="24"
      maskUnits="userSpaceOnUse"
      style="mask-type: alpha"
      width="24"
      x="0"
      y="0"
    >
      <rect fill="#D9D9D9" height="24" width="24" />
    </mask>
    <g mask="url(#mask1_2580_16509)">
      <path
        d="M6.2564 18C5.89843 18 5.59954 17.8789 5.35972 17.6366C5.11991 17.3943 5 17.0924 5 16.7308V8.26922C5 7.9076 5.11991 7.60565 5.35972 7.36339C5.59954 7.12113 5.89843 7 6.2564 7H17.7436C18.1016 7 18.4005 7.12113 18.6403 7.36339C18.8801 7.60565 19 7.9076 19 8.26922V16.7308C19 17.0924 18.8801 17.3943 18.6403 17.6366C18.4005 17.8789 18.1016 18 17.7436 18H6.2564ZM12 12.5907L5.77778 8.48078V16.7308C5.77778 16.8718 5.82265 16.9876 5.91239 17.0783C6.00213 17.169 6.1168 17.2143 6.2564 17.2143H17.7436C17.8832 17.2143 17.9979 17.169 18.0876 17.0783C18.1774 16.9876 18.2222 16.8718 18.2222 16.7308V8.48078L12 12.5907ZM12 11.7143L17.9829 7.78571H6.0171L12 11.7143ZM5.77778 8.48078V7.78571V16.7308C5.77778 16.8718 5.82265 16.9876 5.91239 17.0783C6.00213 17.169 6.1168 17.2143 6.2564 17.2143H5.77778V8.48078Z"
        fill="white"
      />
    </g>
  </g>
</template>
<script setup></script>
