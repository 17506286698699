import request from "@/utils/request";

export const getPrivacyDataApi = (data) =>
  request({
    url: `/api/V2/data-privacy?${data}`,
    method: "get",
  });

export const postAnonymizeDataApi = (data) =>
  request({
    url: `/api/V2/data-privacy/anonymized`,
    method: "post",
    data,
  });

export const putCancelAnonymizeDataApi = (id) =>
  request({
    url: `/api/V2/data-privacy/cancel/${id}`,
    method: "put",
  });

export const postExportAnonymizeDataApi = (data) =>
  request({
    url: `/api/V2/data-privacy/export`,
    method: "post",
    data,
  });

export const getExportHistoryApi = (data) =>
  request({
    url: `/api/V2/data-privacy/export?${data}`,
    method: "get",
  });
