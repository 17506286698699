import { jsonToFormData } from "@/utils/jsonToFormData";
import { editOrderStatusesApi } from "@/api/orders";
import { useRoute } from "vue-router";
import { useModal } from "@/components/UI/Modal/useModal";
import { useOrdersStatusesActions } from "@/hooks/store/useOrdersStatusesStore";
import { useOptionsStoreActions } from "@/hooks/store/useOptionsStore";
import { onMounted } from "vue";
import useToast from "@/hooks/useToast";

export const useEditStatusDialog = (id) => {
  const { close } = useModal();
  const { openToast } = useToast();
  const { loadOrderStatuses } = useOrdersStatusesActions();
  const { loadOrderStatusOptions } = useOptionsStoreActions();

  const route = useRoute();
  const values = {
    name: null,
  };

  onMounted(() => {
    loadOrderStatusOptions();
  });

  const save = () => {
    const entries = jsonToFormData(values);
    editOrderStatusesApi(id.value, entries)
      .catch((err) => openToast(err.message))
      .finally(() => {
        const params = new URLSearchParams({
          ...route.query,
        });
        close(null);
        loadOrderStatuses(params);
      });
  };

  const cancel = () => {
    close(null);
  };

  return {
    cancel,
    save,
    values,
  };
};
