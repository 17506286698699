// @ts-check
import { getToken } from "@/utils/auth";
import { defineStore } from "pinia";

const persistToken = getToken();

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    profile: null,
    loading: false,
    token: persistToken,
    isLoggedIn: Boolean(persistToken),
  }),
});
