<template>
  <path
    d="M0 12C0 5.37257 5.37257 0 12 0C18.6274 0 23.9999 5.37257 23.9999 12C23.9999 18.6274 18.6274 23.9999 12 23.9999C5.37257 23.9999 0 18.6274 0 12Z"
    fill="#588AD8"
  />
  <path
    clip-rule="evenodd"
    d="M18.375 8.62498H14.625V6.37498H2.62506V16.875H4.12503C4.12503 15.6323 5.13239 14.625 6.37503 14.625C7.61767 14.625 8.62502 15.6323 8.62502 16.875H16.1249C16.1249 15.6323 17.1323 14.625 18.3749 14.625C19.6175 14.625 20.6249 15.6323 20.6249 16.875H21.375V12.375L18.375 8.62498ZM19.8751 12.375L17.6251 9.37498H15.7501V12.375H19.8751ZM6.37502 15.375C5.54659 15.375 4.87502 16.0466 4.87502 16.875C4.87502 17.7034 5.54659 18.375 6.37502 18.375C7.20344 18.375 7.87501 17.7034 7.87501 16.875C7.87501 16.0466 7.20344 15.375 6.37502 15.375ZM7.06812 17.162C7.10581 17.071 7.12521 16.9735 7.12521 16.875H6.37521H5.62522C5.62522 16.9735 5.64461 17.071 5.68231 17.162C5.72 17.253 5.77524 17.3357 5.84488 17.4053C5.91453 17.4749 5.99721 17.5302 6.0882 17.5679C6.1792 17.6056 6.27672 17.625 6.37521 17.625C6.4737 17.625 6.57123 17.6056 6.66223 17.5679C6.75322 17.5302 6.8359 17.4749 6.90554 17.4053C6.97519 17.3357 7.03043 17.253 7.06812 17.162ZM18.3749 15.375C17.5465 15.375 16.8749 16.0466 16.8749 16.875C16.8749 17.7034 17.5465 18.375 18.3749 18.375C19.2034 18.375 19.8749 17.7034 19.8749 16.875C19.8749 16.0466 19.2034 15.375 18.3749 15.375ZM19.0679 17.162C19.1056 17.071 19.125 16.9735 19.125 16.875H18.375H17.625C17.625 16.9735 17.6444 17.071 17.6821 17.162C17.7198 17.253 17.7751 17.3357 17.8447 17.4053C17.9143 17.4749 17.997 17.5302 18.088 17.5679C18.179 17.6056 18.2765 17.625 18.375 17.625C18.4735 17.625 18.5711 17.6056 18.662 17.5679C18.753 17.5302 18.8357 17.4749 18.9054 17.4053C18.975 17.3357 19.0303 17.253 19.0679 17.162Z"
    fill="white"
    fill-rule="evenodd"
  />
</template>
<script setup></script>
