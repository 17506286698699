import { getLaserOrderApi } from "@/api/laserOrder";
import { storeToRefs } from "pinia";
import { useLaserOrderStore } from "@/store/laserOrder";

export const useLaserOrderActions = () => {
  const { loading, item, approved, completed } = storeToRefs(useLaserOrderStore());
  const loadLaserOrder = (params) => {
    loading.value = true;
    return getLaserOrderApi(params)
      .then((response) => {
        item.value = {
          ...response.data.laserOrder,
          orders_laser_orders: response.data.laserOrder.orders_laser_orders.map((order) => ({
            ...order,
            nextDayDelivery: response.data.nextDayDelivery.includes(order.id),
          })),
        };
        approved.value = response.data.approved;
        completed.value = response.data.completed;
        return response;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        loading.value = false;
      });
  };
  return { loadLaserOrder, loading, item, completed, approved };
};
