<template>
  <form action="false" @submit.prevent="handleSubmitChangeStatus">
    <div class="modal_inputs">
      <Multiselect
        label="Select Status"
        placeholder="Status"
        v-model="selectedStatus"
        :options="statuses"
        :classes="{
          containerActive: 'ring ring-hqPurple ring-opacity-30',
          optionSelected: 'bg-hqPurple text-white',
          optionSelectedPointed: 'bg-hqPurple text-white',
        }"
        :filter="customFilter"
        searchable
        class="relative"
      />
    </div>
    <div class="modal_actions">
      <Button secondary-button text="Cancel" @click="handleCancel"></Button>
      <Button :disabled="!selectedStatus" text="Change Status" type="submit" />
    </div>
  </form>
</template>

<script setup>
import Button from "@/components/UI/Form/Button/Button.vue";
import Multiselect from "@/components/UI/Form/Multiselect/Multiselect.vue";
import { onMounted } from "vue";
import { useChangeStatus } from "./ChangeStatus.hook";

const props = defineProps({
  id: Array,
});

const { statuses, selectedStatus, handleSubmitChangeStatus, handleCancel } = useChangeStatus(props);
const customFilter = () => {
  statuses.label.toLowerCase().includes(selectedStatus.toLowerCase());
};
onMounted(() => {
  const elements = document.getElementsByClassName("modal_content");
  Array.from(elements).forEach((element) => element.classList.remove("modal_content"));
});
</script>
