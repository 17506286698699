<template>
  <Loading v-if="!ordersByGroupOptions" />
  <ul v-if="ordersByGroupOptions">
    <li v-for="order in ordersByGroupOptions" :key="order">
      <router-link :custom="true" :to="{ path: order.path }">
        <a
          :href="order.path"
          class="text-underline w-full p-3 block text-hqPurple hover:bg-gray-100"
          @click="handleRouteChange(order.path, $event)"
        >
          {{ order.name }}
        </a>
      </router-link>
    </li>
  </ul>
</template>

<script setup>
import { onMounted, toRefs, onUnmounted } from "vue";
import Loading from "@/components/UI/Loading/Loading.vue";
import { useOptionsStoreActions } from "@/hooks/store/useOptionsStore";
import { useModal } from "@/components/UI/Modal/useModal";
import { useRouter } from "vue-router";

const props = defineProps({
  groupId: String || Number,
});

const { groupId } = toRefs(props);
const { close } = useModal();
const router = useRouter();

const { loadOrdersByGroupOptions, resetOrdersByGroupOptions, ordersByGroupOptions } =
  useOptionsStoreActions();

onMounted(() => {
  loadOrdersByGroupOptions(groupId.value);
});

const handleRouteChange = (order, e) => {
  e.preventDefault();
  router.push({ path: order });
  close();
};

onUnmounted(() => {
  resetOrdersByGroupOptions();
});
</script>
