<template>
  <section class="rounded-4 overflow-hidden h-full">
    <div class="bg-green-600 text-white font-bold text-base leading-5.5 px-2 py-1">
      Order {{ orderId }}
    </div>
    <ul
      ref="chatArea"
      class="flex flex-col gap-2 overflow-y-auto h-full max-h-[260px] p-4 bg-chatBg"
      v-if="isLoggedIn"
    >
      <Loading v-if="loadingChat" />
      <transition-group name="chat">
        <ChatMessage
          v-for="message in messageList"
          :key="message.id"
          :id="`chat-message-${message.id}`"
          :text="message.text"
          :name="message.senderName"
          :currentUserSender="message.senderId === profile.id"
          :date="message.date"
          :read="Boolean(Number(message.read))"
        />
      </transition-group>
    </ul>
  </section>
  <form @submit.prevent="sendMessage" class="flex gap-2 w-full mt-2">
    <div class="flex-1">
      <FormKit v-model="newMessage" type="text" placeholder="Message" />
    </div>
    <Button type="submit" text="Send" :disabled="!newMessage" />
  </form>
</template>

<script setup>
import {
  authInChatApi,
  messagesChatApi,
  readMessagesChatApi,
  sendMessageChatApi,
} from "@/api/chat";
import Button from "@/components/UI/Form/Button/Button.vue";
import { SOCKET_URL } from "@/consts/env";
import { useUserStore } from "@/store/user";
import { storeToRefs } from "pinia";
import { io } from "socket.io-client";
import { nextTick, onMounted, onUnmounted, ref, toRefs, watch } from "vue";
import { format } from "date-fns";
import ChatMessage from "./ChatMessage.vue";
import Loading from "../UI/Loading/Loading.vue";

const emit = defineEmits(["setconnection", "toggleminimize"]);
const { profile, isLoggedIn } = storeToRefs(useUserStore());
const props = defineProps({
  orderId: Number,
  minimized: Boolean,
});
const { orderId, minimized } = toRefs(props);
const chatArea = ref(null);
const chatToken = ref("");
const messageList = ref([]);
const newMessage = ref("");
const connected = ref(false);
const timer = ref(null);
const loadingChat = ref(false);

const createMessage = ({ id, senderName, text, senderId, date, read }) => ({
  id,
  text,
  senderName,
  senderId,
  date,
  read,
});

const toggleMinimize = () => {
  if (minimized.value) {
    emit("toggleminimize", orderId.value);
  }
};
const socket = io(SOCKET_URL, {
  autoConnect: false,
  transports: ["websocket"],
});

const disconnect = () => socket.disconnect();
const connect = () => socket.connect();
const scrollToLastMessage = (lastMessageId) => {
  if (lastMessageId) {
    const lastMessage = document.getElementById(`chat-message-${lastMessageId}`);
    if (lastMessage) {
      lastMessage.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }
};

socket.on("connect", () => {
  connected.value = true;
  socket.emit("join", `orders.${orderId.value}`);
});

socket.on("disconnect", () => {
  connected.value = false;
  chatToken.value = "";
});
socket.on("connect_error", (err) => {
  console.error(`connect_error due to ${err.message}`);
});
socket.on("error", (err) => {
  console.error(`error due to ${err.message}`);
});
socket.on("NewMessage", ({ message: messageData }) => {
  toggleMinimize();
  messageList.value = [
    ...messageList.value,
    createMessage({
      id: messageData.message_id,
      senderName: messageData.sender_name,
      text: messageData.message,
      senderId: messageData.sender_id,
      date: format(new Date(), "HH:mm"),
      read: 1,
    }),
  ];

  nextTick(() => {
    scrollToLastMessage(messageData.message_id);
  });
});

onMounted(() => {
  if (connected.value) {
    timer.value = setInterval(() => {
      readMessagesChatApi(orderId.value);
    }, 10000);
    timer.value();
  }
  loadingChat.value = true;
  authInChatApi(orderId.value)
    .then((res) => {
      chatToken.value = res.data.token;
      socket.auth = {
        token: res.data.token,
        roomName: `orders.${orderId.value}`,
      };
    })
    .then(() => {
      connect();
      messagesChatApi(orderId.value)
        .then((res) => {
          messageList.value = res.data.result.reverse().map((el) =>
            createMessage({
              id: el.id,
              senderName: el.user.first_name,
              text: el.content,
              senderId: el.user.id,
              date: format(new Date(el.created), "HH:mm"),
              read: el.read,
            })
          );
        })
        .then(() => {
          nextTick(() => {
            const lastMessageId =
              messageList.value.length > 0 ? messageList.value.slice(-1)?.[0].id : null;
            scrollToLastMessage(lastMessageId);
          });
          readMessagesChatApi(orderId.value);
        });
    })
    .finally(() => {
      loadingChat.value = false;
    });
});

onUnmounted(() => {
  disconnect();
  clearInterval(timer.value);
  timer.value = null;
});

const sendMessage = () => {
  sendMessageChatApi(orderId.value, { message: newMessage.value }).then(() => {
    newMessage.value = "";
  });
};

watch(connected, () => {
  emit("setconnection", connected.value);
});

defineExpose({ connect, disconnect });
</script>
