import { useChatStore } from "@/store/chat";
import { storeToRefs } from "pinia";

export const useChatPopup = () => {
  const store = useChatStore();
  const { openedChatIds } = storeToRefs(store);

  const prepareChat = (id, title, minimized) => ({
    id,
    title,
    minimized,
  });

  const close = (id) => {
    openedChatIds.value = openedChatIds.value.filter((chat) => chat.id !== id);
  };

  const open = ({ id, title }) => {
    if (openedChatIds.value.find((chat) => chat.id === id)) {
      close(id);
      return;
    }
    openedChatIds.value = [...openedChatIds.value, prepareChat(id, title, false)];
  };

  const toggleMinimize = (id) => {
    openedChatIds.value = openedChatIds.value.map((chat) => {
      if (chat.id === id) {
        return {
          ...chat,
          minimized: !chat.minimized,
        };
      }
      return chat;
    });
  };

  return {
    openedChatIds,
    open,
    close,
    toggleMinimize,
  };
};
