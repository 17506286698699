<template>
  <div class="bg-white shadow-login-form rounded px-12 pt-6 pb-8 mb-4">
    <form @submit.prevent="onSubmit">
      <div class="mb-4">
        <FormKit v-model="username" label="Username" placeholder="Username" type="text" />
      </div>
      <div class="mb-6">
        <FormKit v-model="password" label="Password" placeholder="Password" type="password" />
      </div>
      <Button text="Sign In" type="submit"></Button>
    </form>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useAppStoreActions } from "@/hooks/store/useApp";
import { useUserStoreActions } from "@/hooks/store/useUserStore";
import Button from "@/components/UI/Form/Button/Button.vue";
import ROLES from "@/consts/userRoles";
import { useRouter } from "vue-router";

const router = useRouter();
const { auth, profile } = useUserStoreActions();
const { setLoading } = useAppStoreActions();
const username = ref("");
const password = ref("");

const onSubmit = () => {
  setLoading(true, { text: "Logging in..." });
  auth({ username: username.value, password: password.value })
    .then(() => {
      switch (profile.value.group_id) {
        case ROLES.MANAGERS.id:
          router.push({ path: ROLES.MANAGERS.home_page });
          break;
        case ROLES.ADMINISTRATORS.id:
          router.push({ path: ROLES.ADMINISTRATORS.home_page });
          break;
        case ROLES.LASER_PRINTERS.id:
          router.push({ path: ROLES.LASER_PRINTERS.home_page });
          break;
        default:
          router.push("/crm/orders");
      }
    })
    .finally(() => setLoading(false));
};
</script>

<style lang="scss" scoped></style>
