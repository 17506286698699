<template>
  <form actions="false" @submit.prevent="handleSubmitShipByUber">
    <div class="modal_inputs">
      <FormLabel label="Total Charge" required>
        <FormKit v-model="formData.charge" placeholder="Total charge" type="text" />
      </FormLabel>
      <FormLabel label="Tracking Number" required>
        <FormKit v-model="formData.trackingNumber" placeholder="Tracking number" type="text" />
      </FormLabel>
      <FormLabel label="Tracking URL" required>
        <FormKit v-model="formData.trackingUrl" placeholder="Tracking URL" type="textarea" />
      </FormLabel>
    </div>
    <div class="modal_actions">
      <Button secondary-button text="Cancel" @click="handleCancel"></Button>
      <Button text="Ship by Uber" type="submit" />
    </div>
  </form>
</template>

<script setup>
import { ref, toRefs } from "vue";
import Button from "@/components/UI/Form/Button/Button.vue";
import { useModal } from "@/components/UI/Modal/useModal";
import { shipByUberApi } from "@/api/shipping";
import { useRouter } from "vue-router";
import { useAppStoreActions } from "@/hooks/store/useApp";
import useToast from "@/hooks/useToast";
import { ENTRY_POINTS } from "@/views/Shipping/Shipping.consts";
import FormLabel from "@/components/UI/Form/FormLabel.vue";

const { setLoading } = useAppStoreActions();
const router = useRouter();
const { close, open } = useModal();
const { openToast } = useToast();

const props = defineProps({
  ids: {
    type: Array,
  },
  pageMode: {
    type: String,
  },
});

const { ids, pageMode } = toRefs(props);

const INITIAL_STATE = {
  charge: null,
  trackingNumber: "",
  trackingUrl: "",
};

const formData = ref(INITIAL_STATE);
const validateFormData = () => {
  if (
    !formData.value.trackingNumber.trim() ||
    !formData.value.trackingUrl.trim() ||
    formData.value.charge === null ||
    // eslint-disable-next-line no-restricted-globals
    isNaN(formData.value.charge)
  ) {
    openToast("Uber data must be filled!", "danger");
    return false;
  }
  return true;
};

const handleSubmitShipByUber = () => {
  if (!validateFormData()) {
    return;
  }
  close(null);
  setLoading(true);
  const queryParams = new URLSearchParams(window.location.href.split("?")[1]);
  shipByUberApi({
    tracking_number: formData.value.trackingNumber,
    orderIds: ids.value,
    tracking_url: formData.value.trackingUrl,
    total_charge: formData.value.charge,
    ...(queryParams.has("binning") && {
      binning: queryParams.get("binning").split(",").map(Number),
    }),
  })
    .then(() => {
      openToast("Order will be shipped by Uber", "success");
      router.push("/crm/orders");
      if (pageMode.value === ENTRY_POINTS.G_SHIP) {
        open({
          title: "Shipping",
          component: "Shipping",
        });
      }
    })
    .catch((err) => {
      openToast(err.message);
    })
    .finally(() => {
      setLoading(false);
    });
};

const handleCancel = () => {
  close(null);
};
</script>

<style lang="scss" scoped></style>
