<template>
  <form action="false" @submit.prevent="save">
    <div class="modal_inputs">
      <FormKit
        v-model="values['name']"
        input-class="h-10 w-56"
        label="Name a new order status"
        outer-class="m-0"
        placeholder="Status name"
        type="text"
      />
    </div>
    <div class="modal_actions">
      <Button secondaryButton text="Cancel" @click="cancel" />
      <Button text="Edit a Status" type="submit" />
    </div>
  </form>
</template>

<script setup>
import { computed } from "vue";
import Button from "@/components/UI/Form/Button/Button.vue";
import { useEditStatusDialog } from "./EditStatus.hook";

const props = defineProps({
  selected: Number,
});

const id = computed(() => props.selected[0]);
const { cancel, save, values } = useEditStatusDialog(id);
</script>
