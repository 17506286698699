<template>
  <form @submit.prevent="save">
    <div class="modal_inputs">
      <Text>Please confirm this order is not part of a group before shipping from here</Text>
    </div>
    <div class="modal_actions">
      <Button secondaryButton text="Cancel" @click="cancel" />
      <Button text="OK" type="submit" />
    </div>
  </form>
</template>

<script setup>
import Button from "@/components/UI/Form/Button/Button.vue";
import Text from "@/components/UI/Typography/Text.vue";
import { useModal } from "@/components/UI/Modal/useModal";
import { useRouter } from "vue-router";
import { toRefs } from "vue";

const { close } = useModal();
const router = useRouter();

const cancel = () => {
  close(null);
};

const props = defineProps({
  id: {
    type: String || Number,
    required: true,
  },
});

const { id } = toRefs(props);

const save = () => {
  router.push(`/crm/shipping/${id.value}`);
  close(null);
};
</script>
