import { jsonToFormData } from "@/utils/jsonToFormData";
import { createOrderStatusApi } from "@/api/orders";
import { useRoute } from "vue-router";
import { useOrdersStatusesActions } from "@/hooks/store/useOrdersStatusesStore";
import { useModal } from "@/components/UI/Modal/useModal";
import { useOptionsStoreActions } from "@/hooks/store/useOptionsStore";
import { onMounted } from "vue";
import useToast from "@/hooks/useToast";

export const useNewOrderStatusDialog = () => {
  const { loadOrderStatuses } = useOrdersStatusesActions();
  const { loadOrderStatusOptions } = useOptionsStoreActions();
  const { close } = useModal();
  const { openToast } = useToast();

  const values = {
    name: null,
  };

  onMounted(() => {
    loadOrderStatusOptions();
  });

  const route = useRoute();
  const create = () => {
    const entries = jsonToFormData(values);
    createOrderStatusApi(entries)
      .catch((err) => openToast(err.message))
      .finally(() => {
        const params = new URLSearchParams({
          ...route.query,
        });
        close(null);
        loadOrderStatuses(params);
      });
  };
  return {
    create,
    values,
  };
};
