// @ts-check
import { defineStore } from "pinia";

export const useAppStore = defineStore({
  id: "app",
  state: () => ({
    loading: false,
    loadingText: "Loading, please wait...",
  }),
});
