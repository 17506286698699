<template>
  <form action="false" @submit.prevent="save">
    <div class="modal_inputs">
      <div v-for="(proof, index) in photoProofs" :key="proof">
        <input
          :ref="proof"
          class="file:mr-4 file:py-1 file:px-2 file:rounded file:border file file:text-body-1 file:border-hqPurple300 file:text-sm file:font-semibold file:text-hqPurple file:bg-white file:border-solid hover:file:bg-hoverSecondaryButton mb-2"
          type="file"
          @change="handleChangeProof(index, $event)"
        />
      </div>
      <FormKit v-model="form" label="Content" placeholder="Your message" rows="5" type="textarea" />
    </div>
    <div class="modal_actions">
      <Button secondary-button text="Cancel" @click="cancel"></Button>
      <Button text="Send Proof" type="submit"></Button>
    </div>
  </form>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { zpanelSend } from "@/api/order";
import Button from "@/components/UI/Form/Button/Button.vue";
import { useModal } from "@/components/UI/Modal/useModal";
import useToast from "@/hooks/useToast";
import { useAppStoreActions } from "@/hooks/store/useApp";

const props = defineProps({
  id: Number || String,
});

const { setLoading } = useAppStoreActions();
const { close } = useModal();
const { openToast } = useToast();

const photoProofs = ref([]);

const form = ref("");
const cancel = () => {
  close(null);
};

const save = () => {
  close(null);
  setLoading(true);

  const formData = new FormData();
  photoProofs.value
    .filter((item) => {
      return item.files?.length > 0;
    })
    .forEach((item, index) => {
      if (index === photoProofs.value.length - 1) {
        return;
      }
      formData.append(`photo[${index}]`, item.files[0]);
    });
  formData.append("content", form.value);
  formData.append("order_id", props.id);
  formData.append("update_status", true);

  zpanelSend(formData)
    .then(() => {
      openToast("Proof sent successfully");
    })
    .finally(() => {
      setLoading(false);
    });
};

function addProof() {
  photoProofs.value = [...photoProofs.value, ref(null)];
}

function handleChangeProof(index, event) {
  photoProofs.value[index].files = event.target.files;
  if (index === photoProofs.value.length - 1) {
    addProof();
  }
}

onMounted(() => {
  addProof();
});
</script>
