import { useModal } from "@/components/UI/Modal/useModal";
import { postExportAnonymizeDataApi } from "@/api/settings";
import { ref } from "vue";
import useToast from "@/hooks/useToast";
import { useRoute, useRouter } from "vue-router";
import { useDataPrivacyActions } from "@/hooks/store/usePrivacyDataStore";
import { useAppStoreActions } from "@/hooks/store/useApp";

export const useExportCustomerDataHook = () => {
  const { close } = useModal();
  const { openToast } = useToast();
  const router = useRouter();
  const route = useRoute();
  const { loadDataPrivacy } = useDataPrivacyActions();
  const { setLoading } = useAppStoreActions();

  const error = (message) => openToast(message, "danger");
  const tabs = [
    {
      id: 1,
      name: "By Email",
    },
    {
      id: 2,
      name: "By Seller",
    },
  ];
  const activeTab = ref(1);
  const changeTab = (tab) => {
    activeTab.value = tab;
  };
  const values = {
    email: null,
    seller: 0,
  };
  const handleSubmitExportAnonymize = () => {
    const data = {};
    if (activeTab.value === 1) {
      if (!values.email) {
        error("Email is required.");
        return;
      }
      data.email = values.email;
    } else if (activeTab.value === 2) {
      if (!values.seller) {
        error("Seller is required.");
        return;
      }
      data.seller = parseInt(values.seller, 10);
    }
    setLoading(true);
    close(null);
    postExportAnonymizeDataApi(data)
      .then((response) => {
        const url = `/api/V2/data-privacy/file/${response.data}`;
        window.open(url, "_blank");
      })
      .finally(() => {
        const params = new URLSearchParams({
          ...route.query,
        });
        loadDataPrivacy(params);
        setLoading(false);
      });
  };
  const handleToHistory = () => {
    router.push("/crm/settings/data-privacy/export");
    close(null);
  };
  const handleCancel = () => {
    close();
  };

  return {
    tabs,
    values,
    activeTab,
    changeTab,
    handleCancel,
    handleSubmitExportAnonymize,
    handleToHistory,
  };
};
