import axios from "axios";
import { getToken, removeToken } from "@/utils/auth";
import router from "@/router";
import { useUserStoreActions } from "@/hooks/store/useUserStore";
import useToast from "@/hooks/useToast";

const { openToast } = useToast();
const isInvalidResponseType = (response) => {
  const INVALID_TYPES = [
    "text/html; charset=utf-8",
    "text/html; charset=UTF-8",
    "text/html; charset=ISO-8859-1",
    "text/html;",
    "text/html",
    "text/plain",
  ];
  return response && INVALID_TYPES.includes(response.headers["content-type"]);
};

const request = axios.create({
  timeout: 70000,
});

request.defaults.headers.common["Content-Type"] = "application/json";
request.defaults.headers.common.Accept = "application/json";

request.interceptors.request.use(
  (config) => {
    const TOKEN = getToken();
    return {
      ...config,
      headers: {
        ...config.headers,
        common: {
          ...config.headers.common,
          Authorization: TOKEN ? `Bearer ${TOKEN}` : "",
        },
      },
    };
  },
  (error) =>
    // Do something with request error
    Promise.reject(error)
);

request.interceptors.response.use(
  (response) => {
    if (isInvalidResponseType(response)) {
      const error = new Error("Invalid response type");
      error.response = response;
      openToast(error.message);
      throw error;
    }
    return response;
  },
  (error) => {
    if (
      (error.response.status === 401 && error.response.statusText === "Unauthorized") ||
      (error.response.status === 500 && error.response.data.message === "Expired token")
    ) {
      const { logout } = useUserStoreActions();
      logout();
      router.push("/");
      removeToken();
      localStorage.clear();
      openToast("Unauthorized. Try again!");
    } else if (error.response.status >= 400) {
      const message = error?.response?.data?.message || error?.message || "Error";
      openToast(message);
    }
    return Promise.reject(error);
  }
);
/**
 * @param {string} url
 * @param {string} method
 * @param {object} data
 *
 */
export default request;
