export const PRINTER_ID = "printer_id";
export const EXTRA_PRINTER_ID = "extra_printer";
export const APPLY_RULE = "apply_rule";
export const EXCLUDE_NCL = "exclude_ncl";
export const EXCLUDE_RCCL = "exclude_rccl";
export const SKIP_RULE = "skip_rule";
export const SKIP_RULE_ROCKET = "skip_rule_rocket";

export const INITIAL_FILTERS = [
  { key: PRINTER_ID, init: null, type: "Number" },
  { key: EXTRA_PRINTER_ID, init: [null, null], type: "Array" },
  { key: APPLY_RULE, init: false, type: "Boolean" },
  { key: EXCLUDE_NCL, init: false, type: "Boolean" },
  { key: EXCLUDE_RCCL, init: false, type: "Boolean" },
  { key: SKIP_RULE, init: false, type: "Boolean" },
  { key: SKIP_RULE_ROCKET, init: false, type: "Boolean" },
];
