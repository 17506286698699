import request from "@/utils/request";

export const getOrdersApi = (data) =>
  request({
    url: `/api/V2/orders?${data}`,
    method: "get",
  });

export const downloadInvoice = (data) =>
  request({
    url: "/api/V2/download/invoice",
    method: "post",
    data,
    responseType: "arraybuffer",
  }).then((response) => {
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const objectUrl = URL.createObjectURL(blob);
    window.open(objectUrl, "__blank");
  });

export const downloadExcel = (data) =>
  request({
    url: "/api/V2/download/excel",
    method: "post",
    data,
    responseType: "arraybuffer",
  }).then((response) => {
    const blob = new Blob([response.data], { type: response.headers["content-type"] });
    const objectUrl = URL.createObjectURL(blob);
    window.open(objectUrl, "__blank");
  });

export const downloadOrders = (data) =>
  request({
    url: "/api/V2/download/orders",
    method: "post",
    data,
  });

export const uploadCad = (data) =>
  request({
    url: "/api/V2/upload/cad",
    method: "post",
    data,
  });

export const uploadOptimizePhoto = (data) =>
  request({
    url: "/api/V2/upload/optimized/photo",
    method: "post",
    data,
  });

export const deleteOrderApi = (id) =>
  request({
    url: `/api/V2/orders/${id}`,
    method: "delete",
  });

export const flagOrderApi = (id, data) =>
  request({
    url: `/api/V2/orders/${id}/flag`,
    method: "put",
    data,
  });

export const statusOrderApi = (data) =>
  request({
    url: "/api/V2/orders/change-status",
    method: "put",
    data,
  });

export const getOrderStatusesApi = (data) =>
  request({
    url: `/api/V2/order-statuses?${data}`,
    method: "get",
  });

export const deleteOrderStatusesApi = (id) =>
  request({
    url: `/api/V2/order-statuses/${id}`,
    method: "delete",
  });

export const editOrderStatusesApi = (id, data) =>
  request({
    url: `/api/V2/order-statuses/${id}`,
    method: "put",
    data,
  });

export const createOrderStatusApi = (data) =>
  request({
    url: `/api/V2/order-statuses`,
    method: "post",
    data,
  });

export const redoOrder = (data) =>
  request({
    url: "/api/V2/orders/redo",
    method: "post",
    data,
  });

export const remakeOrder = (id) =>
  request({
    url: `/api/V2/orders/reorder/${id}`,
  });
