<template>
  <form>
    <div class="w-full grid grid-cols-2 gap-16">
      <form class="flex flex-col gap-2">
        <label
          v-for="seller in sellersList"
          :key="seller.id"
          class="flex justify-between"
          @change="handleAddSeller"
        >
          <span class="flex gap-2">
            <input :checked="sellerChecked(seller.id)" :value="seller.id" type="checkbox" />
            <Text>{{ seller.name }}</Text>
          </span>
          <Text>{{ seller.count }}</Text>
        </label>
      </form>
      <form class="flex flex-col gap-2">
        <label
          v-for="inventory in inventoriesList"
          :key="inventory.id"
          class="flex justify-between"
          @change="handleAddInventory"
        >
          <span class="flex gap-2">
            <input
              :checked="inventoryChecked(inventory.id)"
              :value="inventory.id"
              type="checkbox"
            />
            <Text>{{ inventory.name }}</Text>
          </span>
          <Text>{{ inventory.count }}</Text>
        </label>
      </form>
    </div>
  </form>
</template>

<script setup>
import { ref, watch, toRefs, onMounted, onUnmounted } from "vue";
import { useProductionStoreActions } from "@/hooks/store/useProductionStore";
import { reduceInventories } from "@/utils/reduceInventories";
import Text from "@/components/UI/Typography/Text.vue";
import {
  setSelectedItem,
  changeInventoriesList,
  changeSellersList,
} from "@/components/Production/ProductionFlexAssign/ProductionFlexAssign.utils";

const {
  filterOrders,
  regularItemsSellers,
  regularItemsInventories,
  priorityItemsSellers,
  priorityItemsInventories,
  currentInventories,
  currentSellers,
  lastTab,
} = useProductionStoreActions();

const props = defineProps({
  activeTab: Number,
});

const { activeTab } = toRefs(props);

const sellerChecked = (seller) => {
  return currentSellers.value.includes(seller);
};

const inventoryChecked = (inventory) => {
  return currentInventories.value.includes(inventory);
};

const sellersList = ref([]);
const initSellersList = ref([]);
const inventoriesList = ref([]);

const changeCurrentInventories = () => {
  return currentInventories.value.filter((item) => {
    return inventoriesList.value.map((inventory) => inventory.id).includes(item);
  });
};

const changeCurrentSellers = () => {
  return currentSellers.value.filter((item) => {
    return sellersList.value.map((seller) => seller.id).includes(item);
  });
};

const handleAddSeller = (e) => {
  const selectedSeller = Number(e.target.value);

  currentSellers.value = setSelectedItem({
    list: currentSellers.value,
    selectedItem: selectedSeller,
  });

  inventoriesList.value = changeInventoriesList({
    selectedArray: currentSellers.value,
    array: sellersList.value,
    callback: reduceInventories,
  });

  currentInventories.value = changeCurrentInventories();

  filterOrders(currentSellers.value, currentInventories.value, activeTab.value);
};

const handleAddInventory = (e) => {
  const selectedInventory = Number(e.target.value);

  currentInventories.value = setSelectedItem({
    list: currentInventories.value,
    selectedItem: selectedInventory,
  });

  sellersList.value = changeSellersList({
    selectedArray: currentInventories.value,
    array: initSellersList.value,
    callback: (sellers) => sellers,
  });

  currentSellers.value = changeCurrentSellers();

  filterOrders(currentSellers.value, currentInventories.value, activeTab.value);
};

function toggleLists(tab) {
  if (tab === 1) {
    // Means that is regular tab
    sellersList.value = regularItemsSellers.value;
    initSellersList.value = regularItemsSellers.value;
    inventoriesList.value = regularItemsInventories.value;
  } else {
    // Means that is priority tab
    sellersList.value = priorityItemsSellers.value;
    initSellersList.value = priorityItemsSellers.value;
    inventoriesList.value = priorityItemsInventories.value;
  }
}

const unwatch = watch(
  [regularItemsSellers, priorityItemsSellers, regularItemsInventories, priorityItemsInventories],
  () => {
    toggleLists(activeTab.value);

    unwatch();
  }
);

onMounted(() => {
  if (lastTab.value !== activeTab.value) {
    currentInventories.value = [];
    currentSellers.value = [];
  }

  toggleLists(activeTab.value);

  inventoriesList.value = changeInventoriesList({
    selectedArray: currentSellers.value,
    array: sellersList.value,
    callback: reduceInventories,
  });

  sellersList.value = changeSellersList({
    selectedArray: currentInventories.value,
    array: initSellersList.value,
    callback: (sellers) => sellers,
  });
});

onUnmounted(() => {
  lastTab.value = activeTab.value;
});
</script>
