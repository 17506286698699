export const defaultMapper = (data) => {
  return Object.entries(data).map(([key, name]) => ({
    label: name,
    value: key,
  }));
};
export const mapper = (data, onscope) => {
  return data.map((res) => ({
    label: res.name,
    value: res.id,
    ...(onscope && typeof onscope === "function" ? onscope(res) : {}),
  }));
};
