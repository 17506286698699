import { generateClasses } from "@formkit/themes";
import today from "@/utils/todayRule";

const focusBoxShadow =
  "focus-within:border-hqPurple focus-within:shadow-[0_0_0_2px_rgb(112,23,57)]";

const defaultGlobalStyles = {
  label: "font-inter text-body-1 w-full font-normal",
  input: "font-inter",
};

const defaultInputStyles = {
  outer: "m-0 max-w-full",
  wrapper: "m-0 max-w-full",
  inner: `bg-white w-full overflow-hidden shadow-none border border-[#E8ECEE] ${focusBoxShadow}`,
  input: "p-2.5 bg-white",
};

const defaultTextareaStyles = {
  outer: "m-0 max-w-full",
  wrapper: "m-0 max-w-full",
  inner: `bg-white w-full inline-block overflow-hidden shadow-none border border-[#E8ECEE] ${focusBoxShadow} !important`,
  input: "p-2.5 bg-white",
};

const defaultSelectStyles = {
  outer: "m-0 max-w-full",
  wrapper: "m-0 max-w-full",
  inner: `w-full overflow-hidden shadow-none border border-[#E8ECEE] ${focusBoxShadow}`,
  input: "p-2.5 bg-white",
};

const defaultCheckboxStyles = {
  outer: "checkbox m-0",
  decoratorIcon: "!text-hqPurple",
};

const defaultRadioStyles = {
  outer: "radio m-0",
  fieldset: "p-0 !border-0",
  decoratorIcon: "!text-hqPurple",
};

const defaultFileStyles = {
  outer: "m-0 max-w-full",
  wrapper: "m-0 max-w-full",
  inner: `w-full overflow-hidden shadow-none border border-[#E8ECEE] ${focusBoxShadow}`,
  input: "p-2.5",
};

export const formKitTheme = {
  config: {
    classes: generateClasses({
      global: defaultGlobalStyles,
      text: defaultInputStyles,
      number: defaultInputStyles,
      password: defaultInputStyles,
      textarea: defaultTextareaStyles,
      select: defaultSelectStyles,
      email: defaultInputStyles,
      tel: defaultInputStyles,
      search: defaultInputStyles,
      file: defaultFileStyles,
      checkbox: defaultCheckboxStyles,
      radio: defaultRadioStyles,
      date: defaultInputStyles,
      time: defaultInputStyles,
    }),
  },
  rules: { today },
};
