import { ORDER_TYPES, STORE_TYPES, SHIPPING_TYPES } from "@/consts/newOrderFormInputs";
import { createOptionsElement } from "@/utils/createOptionsElement";
import { isValueEmpty } from "@/utils/variableTypes";
import { useOptionsStoreActions } from "@/hooks/store/useOptionsStore";

export const usePreviewOrder = (order) => {
  const { sellersOptions, countriesOptions, shippingServicesOptions } = useOptionsStoreActions();

  const data = order
    ? {
        type: ORDER_TYPES.find((el) => Number(el.value) === Number(order.type))?.label || "",
        seller_id: sellersOptions.value.find((el) => el.value === order.seller_id)?.fullName || "",
        store_id:
          STORE_TYPES.find((el) => Number(el.value) === Number(order.store_id))?.label || "",
        name: order.shipping_address?.name,
        shipping_type: isValueEmpty(
          SHIPPING_TYPES.find((el) => el.value === order.shipping_type)?.label
        ),
        service_id: isValueEmpty(
          // eslint-disable-next-line no-underscore-dangle
          shippingServicesOptions.value.find((el) => el.__original === order.service_id)?.label
        ),
        country: isValueEmpty(
          countriesOptions.value.find((el) => el.value === order?.shipping_address?.country)?.label
        ),
        city: isValueEmpty(order.shipping_address?.city),
        state: isValueEmpty(order.shipping_address?.state),
        zip: isValueEmpty(order.shipping_address?.zip),
        street: isValueEmpty(order.shipping_address?.street.join(", ")),
        phone: isValueEmpty(order.shipping_address?.phone),
        email: isValueEmpty(order.shipping_address?.email),
        currency: isValueEmpty(order?.currency),
      }
    : {};

  const info = [
    createOptionsElement("Type", data.type),
    createOptionsElement("Seller", data.seller_id),
    createOptionsElement("Store", data.store_id),
    createOptionsElement("Currency", data.currency),
    createOptionsElement("Customer", data.name),
    createOptionsElement("Email", data.email),
    createOptionsElement("Phone", data.phone),
  ];

  const shipping = [
    createOptionsElement("Type", data.shipping_type),
    createOptionsElement("Time", data.service_id),
    createOptionsElement("Country", data.country),
    createOptionsElement("City", data.city),
    createOptionsElement("State", data.state),
    createOptionsElement("Zip", data.zip),
    createOptionsElement("Street", data.street),
  ];

  const products = order?.products?.map((product) => {
    const discount = (item) => {
      const type = {
        char: Number(item.discount_type) !== 1 ? "" : "$",
        curr: Number(item.discount_type) === 0 ? "" : order.currency,
      };
      return item.discount
        ? ` Discount: ${type.char}${item.discount}${!type.char ? "%" : ""} ${type.curr}`
        : "";
    };

    return {
      id: product.client_id,
      image: product.photo,
      imageExtra: product.photo_extra,
      details: [
        createOptionsElement("Qty", product.quantity),
        createOptionsElement("# of boxes", product.boxes),
        createOptionsElement("Client #", product.client_id),
        createOptionsElement("Cost", `$${product.cost} ${discount(product)}`),
        createOptionsElement("Flag", product.flag),
      ],
      rectangle: product.rectangle_keychains
        ? {
            qty: product.rectangle_keychains.qty,
            price: `$${product.rectangle_keychains.price}  ${discount(
              product.rectangle_keychains
            )}`,
          }
        : null,
      heart: product.heart_keychains
        ? {
            qty: product.heart_keychains.qty,
            price: `$${product.heart_keychains.price}  ${discount(product.heart_keychains)}`,
          }
        : null,
      lightbase: product.lightbase
        ? {
            type: product.lightbase.value,
            qty: product.lightbase.qty,
            price: `$${product.lightbase.price}  ${discount(product.lightbase)}`,
          }
        : null,
      popup_card: product["3d_pop_up_card"]
        ? {
            type: product["3d_pop_up_card"].value,
            qty: product["3d_pop_up_card"].qty,
            price: `$${product["3d_pop_up_card"].price}  ${discount(product["3d_pop_up_card"])}`,
          }
        : null,
      inscription: product.inscription
        ? {
            first: product.inscription.value[0],
            second: product.inscription.value[1],
            price: `$${product.inscription.price}  ${discount(product.inscription)}`,
          }
        : null,
      metal_plate_for_base: product.metal_plate_for_base
        ? {
            value: product.metal_plate_for_base.value,
            price: `$${product.metal_plate_for_base.price}  ${discount(
              product.metal_plate_for_base
            )}`,
          }
        : null,
      background: product.keep_the_background?.price
        ? {
            price: `$${product.keep_the_background.price}  ${discount(
              product.keep_the_background
            )}`,
          }
        : null,
      digitalPreview: product.digital_preview?.price
        ? {
            price: `$${product.digital_preview.price}  ${discount(product.digital_preview)}`,
          }
        : null,
    };
  });

  return {
    shipping,
    info,
    products,
  };
};
