// @ts-check
import { defineStore } from "pinia";

export const useOrdersStore = defineStore({
  id: "orders",
  state: () => ({
    items: [],
    allStatuses: [],
    pagination: null,
    loading: false,
  }),
});
