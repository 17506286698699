import { defineStore } from "pinia";

export const useInventoriesStore = defineStore({
  id: "inventories",
  state: () => ({
    items: [],
    availableTemplates: [],
    pagination: {},
    loading: false,
    editedInventory: {
      name: null,
      quantity: null,
      weight: null,
      sorting: null,
      spec: null,
      spot: null,
      spots: [],
      template: [],
      new_templates: [],
    },
  }),
});
