import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/user";
import { removeToken, setToken } from "@/utils/auth";
import { getUserDetailsApi, authApi, loginApi, verifyApi, logoutApi } from "@/api/authorization";
import { useOptionsStoreActions } from "@/hooks/store/useOptionsStore";

export const useUserStoreActions = () => {
  const { profile, loading, isLoggedIn, token } = storeToRefs(useUserStore());
  const loadUserDetails = () => {
    loading.value = true;
    return getUserDetailsApi()
      .then((response) => {
        profile.value = response.data;
        return response;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        loading.value = false;
      });
  };
  const auth = ({ username, password }) => {
    const { refreshOptions } = useOptionsStoreActions();

    removeToken();
    refreshOptions();
    return authApi({
      username: username.trim(),
      password,
    })
      .then((response) => {
        const { token: _token } = response.data;
        token.value = _token;
        isLoggedIn.value = true;
        setToken(_token);
        return response;
      })
      .then(() => loadUserDetails())
      .catch((error) => {
        throw error;
      });
  };
  const login = ({ username, password }) => {
    const { refreshOptions } = useOptionsStoreActions();

    removeToken();
    refreshOptions();
    return loginApi({
      username: username.trim(),
      password,
    })
      .then((response) => {
        // eslint-disable-next-line no-underscore-dangle
        const { token: _token, needVerify } = response.data;
        if (needVerify === false) {
          isLoggedIn.value = true;
        }
        token.value = _token;
        setToken(_token);
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
  const verify = ({ code }) => {
    return verifyApi({
      code: code.trim(),
    })
      .then((response) => {
        isLoggedIn.value = true;
        return response;
      })
      .then(() => loadUserDetails())
      .catch((error) => {
        throw error;
      });
  };

  const logout = () => {
    return logoutApi(token.value).then((response) => {
      removeToken();
      // It's a temporary solution, later we will make a time count clearing storage with Pinia.
      localStorage.clear();
      return response;
    });
  };

  return { login, loadUserDetails, logout, profile, loading, isLoggedIn, token, auth, verify };
};
