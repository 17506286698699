import { defineStore } from "pinia";

const initialState = {
  item: {},
  approved: false,
  completed: false,
  loading: false,
};

export const useLaserOrderStore = defineStore({
  id: "laserOrder",
  state: () => initialState,
});
