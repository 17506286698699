import { useOptionsStoreActions } from "@/hooks/store/useOptionsStore";
import { storeToRefs } from "pinia";
import { useOptionsStore } from "@/store/options";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useModal } from "@/components/UI/Modal/useModal";
import { prefillState } from "@/utils/prefillState";

const ID = "id";
const filters = [{ key: ID, init: null, type: "array" }];

export const useInventoriesFilters = () => {
  const { loadInventoriesOptions } = useOptionsStoreActions();
  const { inventoriesOptions } = storeToRefs(useOptionsStore());
  const formData = ref(prefillState(filters));
  const route = useRoute();
  const router = useRouter();
  const { close } = useModal();
  const BASE_PATH = "/crm/inventories/";

  const updateQuery = () => {
    Object.keys(formData.value).forEach((key) => {
      if (!formData.value[key]) {
        delete formData.value[key];
      }
    });
  };
  const onSave = () => {
    formData.value.id = formData.value.id ? formData.value.id.join(",") : null;
    close(null);
    updateQuery();
    router.replace(`${BASE_PATH}?id=${formData.value.id}`);
  };

  const onClear = () => {
    formData.value = prefillState(filters);
    close(null);
    updateQuery();
    router.replace(`${BASE_PATH}`);
  };

  onMounted(() => {
    loadInventoriesOptions();
    filters.forEach(({ key }) => {
      formData.value[key] = route.query[key] ? route.query[key].split(",") : null;
    });
  });

  return {
    inventoriesOptions,
    formData,
    onSave,
    onClear,
  };
};
