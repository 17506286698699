import { getNextOrderApi } from "@/api/order";

export const checkNextOrder = (data) => {
  let nextId = "";
  const { callback, setLoading, router } = data;
  return getNextOrderApi()
    .then((response) => {
      const { id } = response.data;
      nextId = id;
      setLoading(true, { text: "Search new Order..." });
      return callback(id);
    })
    .then(() => {
      router.push({
        path: `/crm/orders/verification/${nextId}`,
      });
    })
    .catch(() => {
      router.push({
        path: "/crm/orders",
      });
    })
    .finally(() => {
      setLoading(false);
    });
};
