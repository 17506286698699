export const ENTRY_POINTS = {
  BINNING: "binning",
  G_SHIP: "gShip",
  VIEW_PAGE: "viewPage",
};

export const BILL_TO_OPTIONS = [
  { label: "Receiver", value: "Receiver" },
  { label: "Shipper", value: "Shipper" },
];

export const FRIDAY = 5; // Starts from 0
export const EIGHT_PM = 19; // Starts from 0
