/* eslint-disable no-undef */
/* eslint-disable default-param-last */
/* eslint-disable no-restricted-syntax */

import { isBoolean } from "@/utils/isTypeHelper";
import { filterHaveArray } from "@/utils/filterHaveArray";

export const getSearchFromState = (initialValue, filterKeys, filterState) => {
  // preserve all existing params
  let nextParams = { ...initialValue };
  const paramKeys = Object.keys(nextParams);
  filterKeys.forEach(({ key }) => {
    if (nextParams[key]) {
      delete nextParams[key];
    }
    const currentKeyHaveArray = filterHaveArray(paramKeys, key);
    if (currentKeyHaveArray.length > 0) {
      currentKeyHaveArray.forEach((k) => {
        delete nextParams[k];
      });
    }
  });
  for (const key in filterState) {
    if (Object.prototype.hasOwnProperty.call(filterState, key)) {
      if (Array.isArray(filterState[key])) {
        nextParams = {
          ...nextParams,
          ...Object.fromEntries(filterState[key].map((value, idx) => [`${key}[${idx}]`, value])),
        };
      } else {
        nextParams = {
          ...nextParams,
          [key]: isBoolean(filterState[key]) ? Number(filterState[key]) : filterState[key],
        };
      }
    }
  }
  return nextParams;
};
