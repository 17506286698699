import { useModal } from "@/components/UI/Modal/useModal";
import { postAnonymizeDataApi } from "@/api/settings";
import { ref } from "vue";
import useToast from "@/hooks/useToast";
import { useAppStoreActions } from "@/hooks/store/useApp";
import { useRoute } from "vue-router";
import { useDataPrivacyActions } from "@/hooks/store/usePrivacyDataStore";

export const useAnonymizeHook = () => {
  const { setLoading } = useAppStoreActions();
  const { close } = useModal();
  const route = useRoute();
  const { openToast } = useToast();
  const { loadDataPrivacy } = useDataPrivacyActions();
  const error = (message) => openToast(message, "danger");
  const tabs = [
    {
      id: 1,
      name: "By Email",
    },
    {
      id: 2,
      name: "By Seller",
    },
  ];
  const activeTab = ref(1);
  const changeTab = (tab) => {
    activeTab.value = tab;
  };
  const values = {
    email: null,
    seller: 0,
  };
  const handleSubmitAnonymize = () => {
    const data = {};
    if (activeTab.value === 1) {
      if (!values.email) {
        error("Email is required.");
        return;
      }
      data.email = values.email;
    } else if (activeTab.value === 2) {
      if (!values.seller) {
        error("Seller is required.");
        return;
      }
      data.seller = parseInt(values.seller, 10);
    }
    setLoading(true);
    close(null);
    postAnonymizeDataApi(data)
      .then((responce) => openToast(responce.data))
      .finally(() => {
        const params = new URLSearchParams({
          ...route.query,
        });
        loadDataPrivacy(params);
        setLoading(false);
      });
  };

  const handleCancel = () => {
    close();
  };

  return {
    tabs,
    values,
    activeTab,
    changeTab,
    handleCancel,
    handleSubmitAnonymize,
  };
};
