<template>
  <form action="false" @submit.prevent="onSave">
    <div class="modal_inputs">
      <Multiselect
        :id="SELLER_FILTER"
        v-model="formData[SELLER_FILTER]"
        :closeOnSelect="false"
        :name="SELLER_FILTER"
        :options="sellersOptions"
        label="Seller"
        mode="tags"
        searchable
        :classes="{
          tag: 'bg-hqPurple300 text-hqPurple rounded flex p-1 pl-2 mb-1 mr-1 text-sm',
        }"
      />
      <Multiselect
        :id="STATUS_FILTER"
        v-model="formData[STATUS_FILTER]"
        :closeOnSelect="false"
        :name="STATUS_FILTER"
        :options="orderStatusOptions"
        label="Status"
        mode="tags"
        searchable
        :classes="{
          tag: 'bg-hqPurple300 text-hqPurple rounded flex p-1 pl-2 mb-1 mr-1 text-sm',
        }"
      />
      <Multiselect
        :id="USER_FILTER"
        v-model="formData[USER_FILTER]"
        :closeOnSelect="true"
        :name="USER_FILTER"
        :options="designersOptions"
        label="Team"
        searchable
        :classes="{
          tag: 'bg-hqPurple300 text-hqPurple rounded flex p-1 pl-2 mb-1 mr-1 text-sm',
        }"
      />
      <Datepicker
        :id="DATES"
        :enableTimePicker="false"
        :modelValue="formData[DATES]"
        :name="DATES"
        format="yyyy-MM-dd"
        label="Created from/to:"
        range
        @update:modelValue="handleDateChange"
      />
      <FormKit
        v-model="formData[EXCLUDE_NCL]"
        :name="EXCLUDE_NCL"
        label="Exclude NCL orders"
        type="checkbox"
      />
      <FormKit
        v-model="formData[EXCLUDE_RCCL]"
        :name="EXCLUDE_RCCL"
        label="Exclude RCCL orders"
        type="checkbox"
      />
    </div>
    <div class="modal_actions">
      <Button secondaryButton text="Clear" @click="onClear" />
      <Button text="Apply" type="submit" />
    </div>
  </form>
</template>

<script setup>
import Multiselect from "@/components/UI/Form/Multiselect/Multiselect.vue";
import Button from "@/components/UI/Form/Button/Button.vue";
import Datepicker from "@/components/UI/Form/Datepicker/Datepicker.vue";
import { useOrderFilters } from "./OrderFilters.hook";
import {
  EXCLUDE_NCL,
  EXCLUDE_RCCL,
  STATUS_FILTER,
  USER_FILTER,
  DATES,
  SELLER_FILTER,
} from "./OrderFilters.consts";

const {
  formData,
  sellersOptions,
  orderStatusOptions,
  designersOptions,
  onSave,
  onClear,
  handleDateChange,
} = useOrderFilters();
</script>
