import { nextTick, onMounted, ref, watch } from "vue";
import { useModal } from "@/components/UI/Modal/useModal";
import { useRouter } from "vue-router";

export const useProcessCheck = (groups) => {
  const optionsArray = ref([]);
  const router = useRouter();
  const selected = ref("");
  const includedOrders = ref("");
  const notIncludedGroups = ref([]);
  const { close } = useModal();

  watch(selected, (newSelected) => {
    optionsArray.value = newSelected.split(/\s+/).filter((n) => n);

    for (let i = 0; i < optionsArray.value.length; i += 1) {
      if (!groups.value.map((item) => item.id).includes(Number(optionsArray.value[i]))) {
        const newArray = [...notIncludedGroups.value, optionsArray.value[i]];
        notIncludedGroups.value = newArray.filter((item, pos) => newArray.indexOf(item) === pos);
      }
    }
  });

  watch(optionsArray, () => {
    includedOrders.value = groups.value
      .filter((group) => selected.value.includes(group.id))
      .map((item) => item.orders)
      .flat(1)
      .join(",");
  });

  const isIncluded = (id) => {
    return optionsArray.value.includes(String(id));
  };

  const handleCancel = () => {
    close(null);
  };

  const handleSubmitProcessCheck = () => {
    includedOrders.value = groups.value
      .filter((group) => selected.value.includes(group.id))
      .map((item) => item.orders)
      .flat(1)
      .join(",");
    router.push({ path: `/crm/shipping/${includedOrders.value}` });
    close(null);
  };

  onMounted(() => {
    nextTick(() => {
      const processCheckScanner = document.getElementById("processCheckScanner");
      processCheckScanner.focus();
    });
  });

  return {
    selected,
    notIncludedGroups,
    isIncluded,
    includedOrders,
    handleSubmitProcessCheck,
    handleCancel,
  };
};
