import { storeToRefs } from "pinia";
import { getCubbyApi } from "@/api/cubby";
import { useCubbyStore } from "@/store/cubby";

export const useCubbyStoreActions = () => {
  const { loading, items } = storeToRefs(useCubbyStore());
  const loadCubby = (params) => {
    loading.value = true;
    return getCubbyApi(params)
      .then((response) => {
        items.value = response.data;
        return response;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        loading.value = false;
      });
  };
  return { loadCubby, loading, items };
};
