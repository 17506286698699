import { computed, onMounted, ref } from "vue";
import { useModal } from "@/components/UI/Modal/useModal";
import { useOptionsStoreActions } from "@/hooks/store/useOptionsStore";
import { postInventoryApi } from "@/api/inventories";
import { newInventoryValidate } from "@/utils/orderValidator";
import { useInventoriesActions } from "@/hooks/store/useInventoriesStore";
import { setQueryParam } from "@/utils/setSearchParam";
import { useRoute, useRouter } from "vue-router";

export const useNewInventoryDialog = () => {
  const { close } = useModal();
  const { loadInventories } = useInventoriesActions();
  const PAGE_PARAM = "page";
  const route = useRoute();
  const router = useRouter();
  const currentPage = computed(() => route.query?.page || 1);

  const form = ref({
    name: null,
    quantity: null,
    weight: null,
    sorting: null,
    spec: null,
    spot: null,
    burn_time: null,
    templates: [],
    inventory_spots: {},
  });
  const { lasersOptions, loadLasersOptions } = useOptionsStoreActions();
  onMounted(() => {
    loadLasersOptions();
  });
  const selectedLaser = ref(null);
  const selectedQty = ref(null);
  const inventorySpots = ref([]);
  const handleFileUpload = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const fileReader = new FileReader();
      fileReader.onload = ({ target }) => {
        form.value.templates = [
          {
            data: target.result,
            filename: files[0].name,
          },
        ];
      };
      fileReader.readAsDataURL(files[0]);
    } else {
      form.value.templates = null;
    }
  };
  const tabs = [
    {
      id: 1,
      name: "General",
    },
    {
      id: 2,
      name: "Lasers",
    },
  ];
  const activeTab = ref(1);

  const handleAdd = () => {
    if (selectedLaser.value && selectedQty.value) {
      const existingSpot = inventorySpots.value.find((spot) => spot.id === selectedLaser.value);
      if (existingSpot) {
        existingSpot.qty += Number(selectedQty.value);
      } else {
        const laserOption = lasersOptions.value.find(
          // eslint-disable-next-line no-underscore-dangle
          (option) => option.__original === selectedLaser.value
        );
        if (laserOption) {
          inventorySpots.value.push({
            id: selectedLaser.value,
            qty: selectedQty.value,
            label: laserOption.label,
          });
        }
      }
      selectedLaser.value = null;
      selectedQty.value = null;
    }
  };
  const handleRemove = (id) => {
    inventorySpots.value.splice(
      inventorySpots.value.findIndex((spot) => spot.id === id),
      1
    );
  };
  const nextTab = () => {
    activeTab.value += 1;
  };
  const prevTab = () => {
    activeTab.value = Math.max(1, activeTab.value - 1);
  };
  const changeTab = (tab) => {
    activeTab.value = tab;
  };
  const cancel = () => {
    if (activeTab.value === 1) {
      close(null);
    } else {
      prevTab();
    }
  };
  const handleSubmit = () => {
    if (newInventoryValidate(form.value)) {
      form.value.inventory_spots = inventorySpots.value.reduce((acc, item) => {
        acc[item.id] = parseInt(item.qty, 10);
        return acc;
      }, {});
      postInventoryApi(form.value).finally(() => {
        const params = new URLSearchParams({
          ...route.query,
          currentPage: currentPage.value,
        });
        setQueryParam({
          history: router,
          location: window.location,
          parameter: PAGE_PARAM,
          replace: false,
          value: currentPage.value,
        });
        close(null);
        loadInventories(params);
      });
    }
  };
  return {
    tabs,
    activeTab,
    lasersOptions,
    selectedLaser,
    selectedQty,
    inventorySpots,
    form,
    handleFileUpload,
    handleAdd,
    handleRemove,
    handleSubmit,
    nextTab,
    changeTab,
    cancel,
  };
};
