<template>
  <path
    d="M14.6,15.9c0,0.4,0,0.8,0,1.2c0.3,0,0.6,0,0.8,0.1c0.9,0.2,1.7,1.1,1.7,2c0,0.3-0.2,0.5-0.4,0.5c-0.1,0-0.2,0-0.3,0
  c-3.6,0-7.1,0-10.7,0c-0.7,0-0.8-0.2-0.7-0.9c0.2-1,1-1.6,2-1.7c0.1,0,0.3,0,0.4,0c0-0.4,0-0.8,0-1.2c-0.1,0-0.2,0-0.3,0
  c-1.8,0-3.6,0-5.4,0c-1.1,0-1.7-0.8-1.7-1.7C0,10,0,5.8,0,1.7C0,0.6,0.6,0,1.7,0c6.2,0,12.4,0,18.6,0C21.4,0,22,0.6,22,1.7
  c0,4.2,0,8.3,0,12.5c0,1.1-0.6,1.7-1.7,1.7c-1.8,0-3.6,0-5.4,0C14.8,15.9,14.7,15.9,14.6,15.9z M21.1,12c0-0.1,0-0.2,0-0.3
  c0-3.3,0-6.6,0-9.9c0-0.6-0.2-0.9-0.8-0.9c-6.2,0-12.4,0-18.5,0c-0.6,0-0.8,0.2-0.8,0.9c0,3.3,0,6.6,0,9.9c0,0.1,0,0.2,0,0.3
  C7.6,12,14.4,12,21.1,12z M11,14.4c0.4,0,0.7,0,1.1,0c0.3,0,0.5-0.2,0.5-0.4c0-0.3-0.2-0.4-0.5-0.4c-0.7,0-1.4,0-2.1,0
  c-0.3,0-0.5,0.2-0.5,0.4c0,0.3,0.2,0.4,0.5,0.4C10.3,14.4,10.7,14.4,11,14.4z"
  />
</template>
