<template>
  <transition>
    <div v-if="opened" :role="options.role" class="modal_mask">
      <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
      <div
        ref="backdrop"
        :class="[isFilter ? 'modal_filter_backdrop' : 'modal_backdrop']"
        @click="dismiss()"
      >
        <div
          ref="container"
          :class="[
            isPopup && 'modal_popup',
            isDrawer && 'modal_drawer',
            isFilter && 'modal_filter',
          ]"
          class="modal_body"
          tabindex="-1"
          @click.stop
          @keydown.esc="dismissedOnEsc && dismiss()"
        >
          <div class="modal_header">
            <div class="modal_header_container">
              <h1 v-show="options.title" class="text-heading-1-bold font-bold select-none">
                {{ options.title }}
              </h1>
              <Button classes="!outline-0" rigthIcon="close" secondaryButton @click="dismiss()" />
            </div>
          </div>
          <div
            :class="[
              isPopup && 'modal_content max-h-[700px]',
              isDrawer && 'modal_content h-[95%]',
              isFilter && 'modal_content max-h-[700px]',
            ]"
          >
            <div class="modal_content_container">
              <component :is="BODIES[options.component]" v-bind="options.props" />
            </div>
          </div>
          <div tabindex="0" @focus.stop="redirectFocus"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, nextTick, watch } from "vue";
import Button from "@/components/UI/Form/Button/Button.vue";
import { useModal } from "./useModal";
import { BODIES } from "./Modal.consts";

const container = ref(null);
const backdrop = ref(null);
const lastfocusedElement = ref(null);

function restrictFocus(e) {
  if (!container.value.contains(e.target)) {
    e.stopPropagation();
    container.value.focus();
  }
}

function open() {
  nextTick(() => {
    lastfocusedElement.value = document.activeElement;
    container.value.focus();
    document.addEventListener("focus", restrictFocus, true);
  });
}

const redirectFocus = () => {
  container.value.focus();
};

const { opened, options, clear, dismiss } = useModal();

const dismissedOnEsc = ref(false);
const dismissedOnBackdrop = ref(false);
const dismissedOnClose = ref(false);

const isPopup = ref(null);
const isDrawer = ref(null);
const isFilter = ref(null);
const teardownModal = () => {
  document.removeEventListener("focus", restrictFocus, true);
  if (lastfocusedElement.value) {
    lastfocusedElement.value.focus();
  }

  clear();
};

const handleClear = () => {
  nextTick(() => {
    teardownModal();
  });
};

const checkType = (type, name) => {
  return options.value.types.includes(type) && type === name;
};

watch(opened, (value) => {
  if (value) {
    open();
    dismissedOnEsc.value = options.value.dismissOn.indexOf("esc") !== -1;
    dismissedOnBackdrop.value = options.value.dismissOn.indexOf("backdrop") !== -1;
    dismissedOnClose.value = options.value.dismissOn.indexOf("close-button") !== -1;
    isPopup.value = checkType(options.value.type, "popup");
    isDrawer.value = checkType(options.value.type, "drawer");
    isFilter.value = checkType(options.value.type, "filter");
  } else {
    handleClear();
  }
});
</script>

<style lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
