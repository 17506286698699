import { defineStore } from "pinia";

export const useProductionStore = defineStore({
  id: "production",
  state: () => ({
    initRegularItems: [],
    regularItems: [],
    regularItemsSellers: [],
    regularItemsInventories: [],
    initPriorityItems: [],
    priorityItems: [],
    priorityItemsSellers: [],
    priorityItemsInventories: [],
    ordersDue: [],
    printers: [],
    loading: false,
    currentInventories: [],
    currentSellers: [],
    lastTab: 1,
  }),
});
