import {
  getCountriesApi,
  getDesignersApi,
  getInventoriesApi,
  getOrderStatusesApi,
  getPrintersApi,
  getProductsApi,
  getSellersApi,
  getStoresApi,
  getOrdersByGroups,
  getShippingServicesApi,
  getArtistTeam,
} from "@/api/options";
import truncateString from "@/utils/truncateString";
import { storeToRefs } from "pinia";
import { useOptionsStore } from "@/store/options";
import { defaultMapper, mapper } from "@/utils/mapperForStores";

export const useOptionsStoreActions = () => {
  const {
    sellersOptions,
    orderStatusOptions,
    designersOptions,
    inventoriesOptions,
    countriesOptions,
    productsOptions,
    storesOptions,
    ordersByGroupOptions,
    shippingServicesOptions,
    lasersOptions,
    refreshSellerOptions,
    refreshOrderStatusOptions,
    refreshDesignersOptions,
    refreshInventoriesOptions,
    refreshCountriesOptions,
    refreshProductsOptions,
    refreshStoresOptions,
    refreshOrdersByGroupOptions,
    refreshShippingServicesOptions,
    refreshLasersOptions,
    artistTeamOptions,
    refreshArtistTeamOptions,
  } = storeToRefs(useOptionsStore());
  const refreshOptions = () => {
    refreshSellerOptions.value = true;
    refreshOrderStatusOptions.value = true;
    refreshDesignersOptions.value = true;
    refreshLasersOptions.value = true;
    refreshInventoriesOptions.value = true;
    refreshCountriesOptions.value = true;
    refreshProductsOptions.value = true;
    refreshStoresOptions.value = true;
    refreshOrdersByGroupOptions.value = true;
    refreshShippingServicesOptions.value = true;
    refreshArtistTeamOptions.value = true;
  };
  const loadSellersOptions = () => {
    if (refreshSellerOptions.value && sellersOptions.value.length > 0) {
      sellersOptions.value = [];
    }
    if (!refreshSellerOptions.value && sellersOptions.value.length > 0) {
      return Promise.resolve(sellersOptions.value);
    }
    return getSellersApi()
      .then((response) => {
        sellersOptions.value = mapper(response.data, (seller) => ({
          fullName: seller.name,
          label: truncateString(seller.name, 30),
          value: `${seller.id}`,
        }));
        refreshSellerOptions.value = false;
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
  const loadOrderStatusOptions = () => {
    if (refreshOrderStatusOptions.value && orderStatusOptions.value.length > 0) {
      orderStatusOptions.value = [];
    }
    if (!refreshOrderStatusOptions.value && orderStatusOptions.value.length > 0) {
      return Promise.resolve(orderStatusOptions.value);
    }
    return getOrderStatusesApi()
      .then((response) => {
        orderStatusOptions.value = mapper(response.data, (status) => ({ value: status.id }));
        refreshOrderStatusOptions.value = false;
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
  const loadDesignersOptions = () => {
    if (refreshDesignersOptions.value && designersOptions.value.length > 0) {
      designersOptions.value = [];
    }
    if (!refreshDesignersOptions.value && designersOptions.value.length > 0) {
      return Promise.resolve(designersOptions.value);
    }
    return getDesignersApi()
      .then((response) => {
        designersOptions.value = defaultMapper(response.data);
        refreshDesignersOptions.value = false;
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
  const loadCountriesOptions = () => {
    if (refreshCountriesOptions.value && countriesOptions.value.length > 0) {
      countriesOptions.value = [];
    }
    if (!refreshCountriesOptions.value && countriesOptions.value.length > 0) {
      return Promise.resolve(countriesOptions.value);
    }
    return getCountriesApi()
      .then((response) => {
        countriesOptions.value = defaultMapper(response.data);
        refreshCountriesOptions.value = false;
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
  const loadProductsOptions = () => {
    if (refreshProductsOptions.value && productsOptions.value.length > 0) {
      productsOptions.value = [];
    }
    if (!refreshProductsOptions.value && productsOptions.value.length > 0) {
      return Promise.resolve(productsOptions.value);
    }
    return getProductsApi()
      .then((response) => {
        productsOptions.value = mapper(response.data, (products) => ({
          label: products.text,
          value: products.value,
          image: products.image,
        }));
        refreshProductsOptions.value = false;
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
  const loadLasersOptions = () => {
    if (refreshLasersOptions.value && lasersOptions.value.length > 0) {
      lasersOptions.value = [];
    }
    if (!refreshLasersOptions.value && lasersOptions.value.length > 0) {
      return Promise.resolve().then(() => lasersOptions);
    }
    return getPrintersApi()
      .then((response) => {
        lasersOptions.value = mapper(response.data, (printers) => ({
          label: printers.name,
          value: printers.id,
        }));
        refreshLasersOptions.value = false;
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
  const loadInventoriesOptions = () => {
    if (!refreshInventoriesOptions.value && inventoriesOptions.value.length > 0) {
      inventoriesOptions.value = [];
    }
    if (!refreshInventoriesOptions.value && inventoriesOptions.value.length > 0) {
      return Promise.resolve().then(() => inventoriesOptions);
    }
    return getInventoriesApi()
      .then((response) => {
        inventoriesOptions.value = response.data;
        refreshInventoriesOptions.value = false;
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
  const loadStoresOptions = () => {
    if (refreshStoresOptions.value && storesOptions.value.length > 0) {
      storesOptions.value = [];
    }
    if (!refreshStoresOptions.value && storesOptions.value.length > 0) {
      return Promise.resolve().then(() => storesOptions);
    }
    return getStoresApi()
      .then((response) => {
        storesOptions.value = mapper(response.data, (store) => ({
          label: store.name,
          value: store.id,
        }));
        refreshStoresOptions.value = false;
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
  const loadOrdersByGroupOptions = (param) => {
    if (refreshOrdersByGroupOptions.value && ordersByGroupOptions.value.length > 0) {
      ordersByGroupOptions.value = [];
    }
    if (!refreshOrdersByGroupOptions.value && ordersByGroupOptions.value.length > 0) {
      return Promise.resolve().then(() => ordersByGroupOptions);
    }
    return getOrdersByGroups(param)
      .then((response) => {
        ordersByGroupOptions.value = mapper(response.data, (order) => ({
          name: order,
          path: `orders/order/${order}`,
        }));
        refreshOrdersByGroupOptions.value = false;
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  const loadShippingServicesOptions = () => {
    if (refreshShippingServicesOptions.value && shippingServicesOptions.value.length > 0) {
      shippingServicesOptions.value = [];
    }
    if (!refreshShippingServicesOptions.value && shippingServicesOptions.value.length > 0) {
      return Promise.resolve(shippingServicesOptions.value);
    }
    return getShippingServicesApi()
      .then((response) => {
        shippingServicesOptions.value = mapper(response.data, (service) => ({
          label: `${service.name} – ${service.description}`,
          value: service.id,
        }));
        refreshShippingServicesOptions.value = false;
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
  const loadArtistTeamOptions = () => {
    if (refreshArtistTeamOptions.value && artistTeamOptions.value.length > 0) {
      artistTeamOptions.value = [];
    }
    if (!refreshArtistTeamOptions.value && artistTeamOptions.value.length > 0) {
      return Promise.resolve(artistTeamOptions.value);
    }
    return getArtistTeam()
      .then((response) => {
        artistTeamOptions.value = [
          { value: 0, label: "" },
          ...Object.entries(response.data).map(([id, label]) => ({
            value: parseInt(id, 10),
            label,
          })),
        ];
        refreshArtistTeamOptions.value = false;
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
  const resetOrdersByGroupOptions = () => {
    ordersByGroupOptions.value = [];
  };
  return {
    refreshOptions,
    loadSellersOptions,
    loadOrderStatusOptions,
    loadDesignersOptions,
    loadCountriesOptions,
    loadProductsOptions,
    loadInventoriesOptions,
    loadStoresOptions,
    loadOrdersByGroupOptions,
    loadShippingServicesOptions,
    loadLasersOptions,
    resetOrdersByGroupOptions,
    loadArtistTeamOptions,
    sellersOptions,
    orderStatusOptions,
    designersOptions,
    inventoriesOptions,
    countriesOptions,
    productsOptions,
    storesOptions,
    ordersByGroupOptions,
    shippingServicesOptions,
    lasersOptions,
    artistTeamOptions,
  };
};
