<template>
  <rect fill="#EE1C25" height="24" rx="12" width="24" />
  <path
    d="M7.80995 7.72583L10.1646 14.9728L4 10.4939H11.6199L5.45527 14.9728L7.80995 7.72583Z"
    fill="#FFFF00"
  />
  <path
    d="M15.0092 6.49696L14.7862 9.02712L13.4794 6.84912L15.8168 7.84304L13.3416 8.41284L15.0092 6.49696Z"
    fill="#FFFF00"
  />
  <path
    d="M18.1157 9.46672L16.9284 11.7121L16.5692 9.19764L18.3377 11.0207L15.8354 10.5853L18.1157 9.46672Z"
    fill="#FFFF00"
  />
  <path
    d="M18.4117 13.9446L16.4117 15.5103L17.1094 13.0681L17.9804 15.454L15.8734 14.0357L18.4117 13.9446Z"
    fill="#FFFF00"
  />
  <path
    d="M14.9578 15.8237L14.8399 18.3609L13.4439 16.239L15.8206 17.1351L13.3711 17.8071L14.9578 15.8237Z"
    fill="#FFFF00"
  />
</template>
