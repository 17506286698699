import { storeToRefs } from "pinia";
import { useInventoriesStore } from "@/store/inventories";
import { getInventoriesApi } from "@/api/inventories";

export const useInventoriesActions = () => {
  const { loading, items, availableTemplates, pagination } = storeToRefs(useInventoriesStore());

  const loadInventories = (params) => {
    loading.value = true;
    return getInventoriesApi(params)
      .then((response) => {
        const {
          inventories: _inventories,
          availableTemplates: _availableTemplates,
          pagination: _pagination,
        } = response.data;
        pagination.value = _pagination;
        availableTemplates.value = _availableTemplates;
        items.value = _inventories.map((inventory) => ({
          ...inventory,
          isSelected: false,
        }));
        return response;
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const getSingleInventory = (id) =>
    items.value.find((item) => {
      return item.id === Number(id);
    });

  return { loading, items, availableTemplates, loadInventories, pagination, getSingleInventory };
};
