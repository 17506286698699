// @ts-check
import { defineStore } from "pinia";

export const useCubbyStore = defineStore({
  id: "cubby",
  state: () => ({
    items: [],
    loading: false,
  }),
});
