import { createApp } from "vue";
import { plugin, defaultConfig } from "@formkit/vue";
// eslint-disable-next-line import/extensions
import "@formkit/themes/genesis";
import moshaToast from "mosha-vue-toastify";
import vClickOutside from "click-outside-vue3";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import loadFonts from "./plugins/webfontloader";
import "./index.scss";
import "mosha-vue-toastify/dist/style.css";
import { formKitTheme } from "./theme/theme";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

loadFonts();

createApp(App)
  .use(router)
  .use(pinia)
  .use(moshaToast)
  .use(vClickOutside)
  .use(plugin, defaultConfig(formKitTheme))
  .mount("#app");
