const ROLES = {
  ADMINISTRATORS: {
    id: 1,
    name: "administrators",
    created: "2011-02-03 05:24:18",
    modified: "2011-02-03 05:24:18",
    home_page: "/crm/orders?page=1",
    parent_id: null,
  },
  MANAGERS: {
    id: 2,
    name: "managers",
    created: "2011-02-03 05:24:36",
    modified: "2011-02-03 05:24:36",
    home_page: "/crm/orders?page=1",
    parent_id: null,
  },
  LASER_PRINTERS: {
    id: 18,
    name: "Laser Printer",
    created: "2021-05-25 05:49:51",
    modified: "2021-05-25 05:49:51",
    home_page: "/crm/production",
    parent_id: null,
  },
};

export default ROLES;
