<template>
  <button class="font-bold block p-2" @click="copy" @keyup.enter="copy">
    <p
      :class="[
        type !== 'default' && 'text-white',
        'pl-4 block text-left text-gray-700 hover:text-gray-400',
      ]"
    >
      {{ title }}
    </p>
  </button>
  <div class="flex p-2 gap-2">
    <Button
      v-if="showButton"
      :class="[type !== 'default' && 'text-white']"
      class="p-2 border-0"
      secondary-button
      text="Close all notifications"
      @click="handleClickCloseAllToasts"
    />
  </div>
</template>

<script setup>
import { toRefs } from "vue";
import useToast from "@/hooks/useToast";
import Button from "@/components/UI/Form/Button/Button.vue";

const props = defineProps({
  title: String,
  type: String,
  showButton: {
    type: Boolean,
    default: true,
  },
});

const { title, type, showButton } = toRefs(props);
const { openToast, clearAllToast } = useToast();

async function copy() {
  try {
    navigator.clipboard.writeText(title.value).then(() => {
      openToast("Copied to clipboard", "success", 1000, false);
    });
  } catch {
    openToast("Failed to Copy", "danger", 1000, false);
  }
}

const handleClickCloseAllToasts = () => {
  clearAllToast();
};
</script>

<style lang="scss" scoped></style>
