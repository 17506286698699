<template>
  <form action="false" @submit.prevent="handleSubmit">
    <div class="modal_inputs">
      <FormKit
        v-model="byArtist"
        :options="artistTypes"
        label="Who will redo the order?"
        placeholder="Select Designer"
        type="select"
      />
      <FormKit
        v-model="reason"
        class="w-full py-2 px-3 bg-white border border-gray-400 rounded"
        label="Comments"
        placeholder="Tell about important things"
        type="textarea"
      />
      <input
        ref="redoPhoto"
        class="file:mr-4 file:py-1 file:px-2 file:rounded file:border file file:text-body-1 file:border-hqPurple300 file:text-sm file:font-semibold file:text-hqPurple file:bg-white file:border-solid hover:file:bg-hoverSecondaryButton"
        type="file"
      />
    </div>
    <div class="modal_actions">
      <Button secondary-button text="Cancel" @click="handleCancel" />
      <Button text="Redo" type="submit" />
    </div>
  </form>
</template>

<script setup>
import { ref, toRefs } from "vue";
import Button from "@/components/UI/Form/Button/Button.vue";
import { useModal } from "@/components/UI/Modal/useModal";
import { ARTISTS_TYPES } from "./Redo.consts";

const reason = ref("");
const redoPhoto = ref(null);

const props = defineProps({
  callback: Function,
  artistTypes: {
    type: Array,
    default: () => ARTISTS_TYPES,
  },
});

const { close } = useModal();
const { callback, artistTypes } = toRefs(props);

const byArtist = ref(artistTypes.value[0].value);

const handleCancel = () => {
  close(null);
};

const handleSubmit = () => {
  callback.value({
    _formData: redoPhoto.value.files[0],
    by: byArtist.value,
    reason: reason.value,
  });
};
</script>

<style lang="scss" scoped></style>
