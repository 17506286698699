import { onMounted, ref } from "vue";
import { statusOrderApi } from "@/api/orders";
import { useOrdersActions } from "@/hooks/store/useOrdersStore";
import { useAppStoreActions } from "@/hooks/store/useApp";
import { useOptionsStoreActions } from "@/hooks/store/useOptionsStore";
import { useModal } from "@/components/UI/Modal/useModal";
import useToast from "@/hooks/useToast";
import { useRoute } from "vue-router";

export const useChangeStatus = (props) => {
  const { setLoading } = useAppStoreActions();
  const { loadOrders } = useOrdersActions();
  const { orderStatusOptions: statuses, loadOrderStatusOptions } = useOptionsStoreActions();
  const route = useRoute();

  onMounted(() => {
    loadOrderStatusOptions();
  });

  const { close } = useModal();
  const { openToast } = useToast();

  const selectedStatus = ref("");
  const handleSubmitChangeStatus = () => {
    close(null);
    setLoading(true);
    statusOrderApi({ status: selectedStatus.value, order_ids: props.id })
      .then(() => {
        openToast("Status updated successfully");
      })
      .catch((error) => openToast(error.message))
      .finally(() => {
        const params = new URLSearchParams({
          ...route.query,
        });
        setLoading(false);
        selectedStatus.value = null;
        loadOrders(params);
      });
  };

  const handleCancel = () => {
    close(null);
  };

  return { statuses, selectedStatus, handleSubmitChangeStatus, handleCancel };
};
