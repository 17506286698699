export const INITIAL_RECTANGLE_KEYCHAIN_PRICE = 15;
export const INITIAL_HEART_KEYCHAIN_PRICE = 18.5;
export const INITIAL_INSCRIPTION_PRICE = 19.5;
export const INITIAL_KEEP_BACKGROUND_PRICE = 25;
export const INITIAL_DIGITAL_PREVIEW_PRICE = 49;
export const INITIAL_PERSONAL_NOTE_PRICE = 19.75;

export const INITIAL_METALLIC_PLATE_PRICE = 10;

export const LIGHTBASE_PRICES = {
  "Rotating Light Base": 35,
};

export const INITIAL_POPUP_CARD_PRICE = 9.75;
