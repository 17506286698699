import request from "@/utils/request";

export const getBinningApi = (data) =>
  request({
    url: `/api/V2/binning/?${data}`,
    method: "get",
  });

export const deleteBinningApi = (data) =>
  request({
    url: `/api/V2/binning/${data}`,
    method: "delete",
  });

export const editDueDateApi = (data) =>
  request({
    url: "/api/V2/binning/due",
    method: "post",
    data,
  });

export const updateScannedItems = (data) =>
  request({
    url: "/api/V2/binning/order-no",
    method: "post",
    data,
  });

export const addBinningApi = (data) =>
  request({
    url: "/api/V2/binning/add",
    method: "post",
    data,
  });
