import { getBinningApi } from "@/api/binning";
import { storeToRefs } from "pinia";
import { useBinningsStore } from "@/store/binning";
import removeDuplicatesByKey from "@/utils/removeDuplicatesByKey";

export const useBinningStoreActions = () => {
  const { loading, items, sellers, pagination } = storeToRefs(useBinningsStore());
  const loadBinnings = (params) => {
    loading.value = true;
    return getBinningApi(params)
      .then((response) => {
        const { pagination: _pagination, binnings: _binnings, sellers: _sellers } = response.data;
        pagination.value = _pagination;
        items.value = _binnings.map((bin) => ({
          ...bin,
          order_groups: removeDuplicatesByKey(bin.order_groups, "id"),
        }));
        sellers.value = _sellers;
        return response;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        loading.value = false;
      });
  };
  return { loadBinnings, loading, items };
};
