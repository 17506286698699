// @ts-check
import { defineStore } from "pinia";

export const useDataPrivacy = defineStore({
  id: "dataPrivacy",
  state: () => ({
    items: [],
    pagination: null,
    loading: false,
  }),
});
