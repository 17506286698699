// @ts-check
// eslint-disable-next-line import/named
import { defineStore } from "pinia";

export const useOrderStatusesStore = defineStore({
  id: "orderStatuses",
  state: () => ({
    items: [],
    pagination: null,
    loading: false,
  }),
});
