import { useModal } from "@/components/UI/Modal/useModal";
import { computed, ref } from "vue";
import { useAppStoreActions } from "@/hooks/store/useApp";
import { useRoute, useRouter } from "vue-router";
import { isVerifiedOrderApi } from "@/api/shipping";
import { verifyAndRoute } from "@/utils/shippingUtils";
import debounce from "lodash.debounce";
import { SCAN_CODE_DELAY } from "@/consts/scanCode";

export const useShippingModal = () => {
  const { close, open } = useModal();
  const order = ref("");
  const { setLoading } = useAppStoreActions();
  const router = useRouter();
  const route = useRoute();
  const isChecked = ref(false);
  const submitType = ref(0);

  const scanOrder = () => {
    if (order.value) {
      setLoading(true);
      close(null);
      const data = {
        status: "burning",
        binning: 1,
        in_status: 1,
        ids: order.value
          .split(",")
          .map((item) => (item.includes("-") ? Number(item.split("-")[0]) : Number(item)))
          .filter((item) => item !== 0),
      };
      // eslint-disable-next-line no-use-before-define
      debounceUpdate.value?.cancel();
      isVerifiedOrderApi(data)
        .then((res) => {
          setLoading(true);
          verifyAndRoute(res.data, order.value, open, isChecked.value, route.path, router, false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const addComa = () => {
    if (order.value.trim() !== "" && !order.value.endsWith(",")) {
      order.value = `${order.value},`;
    }
    // eslint-disable-next-line no-use-before-define
    debounceUpdate.value?.cancel();
  };

  const debounceUpdate = computed(() => {
    if (submitType.value === 1) {
      return debounce(addComa, SCAN_CODE_DELAY * 2);
    }
    return debounce(scanOrder, SCAN_CODE_DELAY);
  });

  return {
    debounceUpdate,
    scanOrder,
    submitType,
    isChecked,
    order,
  };
};
