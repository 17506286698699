import { storeToRefs } from "pinia";
// eslint-disable-next-line import/named
import { useDataPrivacy } from "@/store/dataPrivacy";
import { getExportHistoryApi, getPrivacyDataApi } from "@/api/settings";

export const useDataPrivacyActions = () => {
  const { loading, items, pagination } = storeToRefs(useDataPrivacy());
  const loadDataPrivacy = (params) => {
    loading.value = true;
    return getPrivacyDataApi(params)
      .then((response) => {
        const { pagination: _pagination, anonymizationRequest } = response.data;
        pagination.value = _pagination;
        items.value = anonymizationRequest;
        return response;
      })
      .finally(() => {
        loading.value = false;
      });
  };
  const loadExportHistory = (params) => {
    loading.value = true;
    return getExportHistoryApi(params)
      .then((response) => {
        const { pagination: _pagination, downloadDataRequest } = response.data;
        pagination.value = _pagination;
        items.value = downloadDataRequest;
        return response;
      })
      .finally(() => {
        loading.value = false;
      });
  };
  return {
    loadDataPrivacy,
    loadExportHistory,
    loading,
    items,
    pagination,
  };
};
