// @ts-check
import { defineStore } from "pinia";

export const useBinningsStore = defineStore({
  id: "binings",
  state: () => ({
    items: [],
    sellers: {},
    pagination: null,
    loading: false,
  }),
});
