<template>
  <div class="flex items-center justify-center h-screen w-screen bg-gray-100">
    <div v-if="!secureMode" class="flex items-center justify-center">
      <Auth />
    </div>
    <div v-if="secureMode" class="flex items-center justify-center">
      <Login v-if="!isAuth" @confirmAuth="confirmAuth" />
      <SetupCode v-if="isAuth" v-bind="{ isProtect, qrLink }" @enterLogin="handleSetupCode" />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Login from "@/components/Login/Login.vue";
import SetupCode from "@/components/Login/SetupCode.vue";
import Auth from "@/components/Auth/Auth.vue";
import { SECURE_MODE } from "@/consts/env";

const isAuth = ref(false);
const qrLink = ref(null);
const isProtect = ref(false);
const secureMode = SECURE_MODE;

const handleSetupCode = () => {
  isProtect.value = true;
};

const confirmAuth = (hasProtect, image = false) => {
  isAuth.value = true;
  if (image !== false) {
    qrLink.value = image;
  }
  isProtect.value = hasProtect;
};
</script>
