import { storeToRefs } from "pinia";
import { useAppStore } from "@/store/app";

export const useAppStoreActions = () => {
  const { loading, loadingText } = storeToRefs(useAppStore());
  const setLoading = (state, options = {}) => {
    loading.value = state;
    loadingText.value = options.text || "Loading, please wait...";
  };
  return { setLoading, loading, loadingText };
};
