import { deleteOrderStatusesApi, getOrderStatusesApi } from "@/api/orders";
import { storeToRefs } from "pinia";
import { useOrderStatusesStore } from "@/store/orderStatuses";
import useToast from "@/hooks/useToast";

export const useOrdersStatusesActions = () => {
  const { loading, items, pagination } = storeToRefs(useOrderStatusesStore());
  const { openToast } = useToast();
  const loadOrderStatuses = (params) => {
    loading.value = true;
    return getOrderStatusesApi(params)
      .then((response) => {
        const { pagination: _pagination, orderStatuses } = response.data;
        pagination.value = _pagination;
        items.value = orderStatuses;
        return response;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        loading.value = false;
      });
  };
  const deleteOrderStatus = (id) => {
    return deleteOrderStatusesApi(id)
      .catch((err) => {
        openToast(err.message);
      })
      .finally(() => {
        this.loadOrderStatuses();
      });
  };
  return { loadOrderStatuses, deleteOrderStatus, items, pagination, loading };
};
