<template>
  <div class="modal_inputs">
    <Tabs :active="activeTab" :class="['mb-4']" :onChange="changeTab" :tabs="tabs" />
    <Tab :id="tabs[0].id" :class="['w-full']" :value="activeTab">
      <form action="false">
        <FormKit
          v-model="values['email']"
          input-class="h-10"
          label="Enter the email to get customer data"
          outer-class="m-0"
          placeholder="Email"
          type="email"
        />
      </form>
    </Tab>
    <Tab :id="tabs[1].id" :value="activeTab">
      <form action="false">
        <FormKit
          v-model="values['seller']"
          input-class="h-10"
          label="Choose seller to get customer data"
          outer-class="m-0"
          placeholder="Seller"
          type="select"
          :options="sellersOptions"
        />
      </form>
    </Tab>
    <Button class="w-fit" secondaryButton text="Exports History" @click="handleToHistory" />
  </div>
  <div class="modal_actions">
    <Button secondaryButton text="Cancel" @click="handleCancel" />
    <Button text="Export Data" @click="handleSubmitExportAnonymize" />
  </div>
</template>
<script setup>
import Tabs from "@/components/UI/Tabs/Tabs.vue";
import Tab from "@/components/UI/Tabs/Tab.vue";
import Button from "@/components/UI/Form/Button/Button.vue";
import { useExportCustomerDataHook } from "@/components/UI/Modal/Bodies/ExportCustomerData/ExportCustomerData.hook";
import { useOptionsStoreActions } from "@/hooks/store/useOptionsStore";
import { onMounted } from "vue";

const { loadSellersOptions, sellersOptions } = useOptionsStoreActions();
const {
  tabs,
  values,
  activeTab,
  changeTab,
  handleCancel,
  handleToHistory,
  handleSubmitExportAnonymize,
} = useExportCustomerDataHook();
onMounted(() => {
  loadSellersOptions();
});
</script>
