export const SHIPPING_INFO_TYPES = {
  ID: "Id",
  ITEMS: "Items",
  SHIPPING_SPEED: "Shipping Speed",
  IMAGE: "Image",
  TEXT_LINE_1: "Text Line 1",
  TEXT_LINE_2: "Text Line 2",
  TEXT_LINE_3: "Text Line 3",
  FLAG: "Flag",
  DIGITAL_PREVIEW: "Digital Preview",
  PERSONAL_GIFT_NOTE: "Personal Gift Note",
  SPECIAL_INSTRUCTIONS: "Special Instructions",
  VIDEO: "Video",
  LASER_TABLES: "Laser Table(s)",
};
