<template>
  <div>
    <div class="modal_inputs">
      <FormKit
        id="shipping-scanner"
        v-model="order"
        label="Order Id"
        placeholder="Scan a Scancode"
        type="text"
        @input="debounceUpdate"
      />
      <FormKit v-model="submitType" :options="SUBMIT_TYPES" type="radio" />
      <FormKit v-model="isChecked" label="Do not print a packing slip" type="checkbox" />
    </div>
    <div class="modal_actions">
      <Button text="Submit" @click="scanOrder" />
    </div>
  </div>
</template>

<script setup>
import { nextTick, onMounted } from "vue";
import Button from "@/components/UI/Form/Button/Button.vue";
import { SUBMIT_TYPES } from "@/components/UI/Modal/Bodies/Shipping/Shipping.consts";
import { useShippingModal } from "@/components/UI/Modal/Bodies/Shipping/Shipping.hook";

const { debounceUpdate, scanOrder, submitType, isChecked, order } = useShippingModal();

onMounted(() => {
  nextTick(() => {
    const shippingScanner = document.getElementById("shipping-scanner");
    shippingScanner.focus();
  });
});
</script>

<style lang="scss" scoped></style>
