// @ts-check
import { defineStore } from "pinia";

export const useOptionsStore = defineStore({
  id: "options",
  state: () => ({
    sellersOptions: [],
    refreshSellerOptions: false,
    orderStatusOptions: [],
    refreshOrderStatusOptions: false,
    designersOptions: [],
    refreshDesignersOptions: false,
    inventoriesOptions: [],
    refreshInventoriesOptions: false,
    countriesOptions: [],
    refreshCountriesOptions: false,
    productsOptions: [],
    refreshProductsOptions: false,
    storesOptions: [],
    refreshStoresOptions: false,
    ordersByGroupOptions: [],
    refreshOrdersByGroupOptions: false,
    shippingServicesOptions: [],
    refreshShippingServicesOptions: false,
    lasersOptions: [],
    refreshLasersOptions: false,
    artistTeamOptions: [],
    refreshArtistTeamOptions: false,
  }),
});
