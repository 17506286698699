<template>
  <div>
    <div class="modal_inputs">
      <div v-for="order in orders" :key="order" class="grid">
        <div v-for="(value, key) in order" :key="value">
          <div v-if="value" class="grid grid-cols-2 mb-2">
            <Text
              v-if="
                (SHIPPING_INFO_TYPES.LASER_TABLES === key && value.length > 0) ||
                key !== SHIPPING_INFO_TYPES.LASER_TABLES
              "
              :class="[key === SHIPPING_INFO_TYPES.ID && 'text-2xl text-hqPurple']"
              :text="key"
            ></Text>
            <div v-if="key === SHIPPING_INFO_TYPES.ITEMS" v-html="value"></div>
            <Text
              v-if="!SPECIAL_FIELDS.includes(key)"
              :class="[key === SHIPPING_INFO_TYPES.ID && 'text-2xl text-hqPurple']"
              :text="value"
            ></Text>
            <div v-if="key === SHIPPING_INFO_TYPES.FLAG" v-html="value"></div>
            <img
              v-if="key === SHIPPING_INFO_TYPES.IMAGE"
              :src="value"
              alt="no picture"
              class="h-auto"
            />
            <a
              v-if="key === SHIPPING_INFO_TYPES.VIDEO"
              :href="value"
              class="text-hqPurple hover:text-hqPurple300 underline break-words"
              >{{ value }}
            </a>
            <div v-if="key === SHIPPING_INFO_TYPES.LASER_TABLES" class="flex flex-col space-y-2">
              <a
                v-for="table in value"
                :key="table"
                :href="table.url"
                class="text-hqPurple hover:text-hqPurple300 underline-offset-2 underline"
                >{{ table.text }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap gap-2">
      <Button class="w-fit" secondary-button text="Cancel" @click="cancel"></Button>
      <Button class="w-fit" text="Verify and Print Packing Slip" @click="verifyAndPrint"></Button>
      <Button
        class="w-fit"
        text="Verify and DO NOT Print Packing Slip"
        @click="verifyAndDontPrint"
      ></Button>
    </div>
  </div>
</template>

<script setup>
import { computed, toRefs } from "vue";
import Button from "@/components/UI/Form/Button/Button.vue";
import { useModal } from "@/components/UI/Modal/useModal";
import Text from "@/components/UI/Typography/Text.vue";
import { ENTRY_POINTS } from "@/views/Shipping/Shipping.consts";
import { useRouter } from "vue-router";
import { SHIPPING_INFO_TYPES } from "./ShippingVerification.consts";

const { open, close } = useModal();
const router = useRouter();

const props = defineProps({
  data: Object,
  scanned: String,
});

const { data, scanned } = toRefs(props);

const orders = computed(() => {
  const keys = Object.keys(data.value.info);
  const items = Object.values(data.value.info);
  return items.map((item, index) => ({
    [SHIPPING_INFO_TYPES.ID]: keys[index],
    [SHIPPING_INFO_TYPES.ITEMS]: item.items,
    [SHIPPING_INFO_TYPES.SHIPPING_SPEED]: item.priority,
    [SHIPPING_INFO_TYPES.IMAGE]: `/files/orders/${keys[index]}/${item.photo}`,
    [SHIPPING_INFO_TYPES.TEXT_LINE_1]: item.text_1,
    [SHIPPING_INFO_TYPES.TEXT_LINE_2]: item.text_2,
    [SHIPPING_INFO_TYPES.TEXT_LINE_3]: item.text_3,
    [SHIPPING_INFO_TYPES.FLAG]: item.flag,
    [SHIPPING_INFO_TYPES.DIGITAL_PREVIEW]: item.digital_preview,
    [SHIPPING_INFO_TYPES.PERSONAL_GIFT_NOTE]: item.personal_gift_note,
    [SHIPPING_INFO_TYPES.SPECIAL_INSTRUCTIONS]: item.special_instructions,
    [SHIPPING_INFO_TYPES.VIDEO]: item.video,
    [SHIPPING_INFO_TYPES.LASER_TABLES]: item.laser_tables.map((link) => ({
      url: `/crm/laser-table/${link}`,
      text: `Laser Table ${link}`,
    })),
  }));
});

const SPECIAL_FIELDS = [
  SHIPPING_INFO_TYPES.IMAGE,
  SHIPPING_INFO_TYPES.FLAG,
  SHIPPING_INFO_TYPES.VIDEO,
  SHIPPING_INFO_TYPES.LASER_TABLES,
  SHIPPING_INFO_TYPES.ITEMS,
];

const cancel = () => {
  close(null);
};

const verifyAndRoute = () => {
  if (data.value.orders.length !== 0) {
    open({
      title: "Status Warning",
      component: "StatusWarning",
      props: {
        message:
          'Unique order ID has not been marked "Laser Completed – Ready to Pack" or "Packed for Shipping" yet"',
        scanned: scanned.value,
        binning: data.value.binning,
      },
    });
  } else if (data.value.binning.binning !== 0) {
    router.push({
      path: `/crm/gather-binning/${scanned.value.split("-")[0]}`,
      query: { prevPage: ENTRY_POINTS.G_SHIP, gship_scanned: scanned.value },
    });
  } else {
    router.push({
      path: `/crm/shipping/${scanned.value.split("-")[0]}`,
      query: { prevPage: ENTRY_POINTS.G_SHIP, gship_scanned: scanned.value },
    });
  }
};

const verifyAndPrint = () => {
  setTimeout(() => {
    window.open(`/files/plabel/${data.value.gid.join("-")}.pdf`);
    verifyAndRoute();
  }, 500);
  close(null);
};

const verifyAndDontPrint = () => {
  setTimeout(() => {
    verifyAndRoute();
  }, 500);
  close(null);
};
</script>
