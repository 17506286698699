<template>
  <form action="false" @submit.prevent="save">
    <div class="modal_inputs">
      <FormKit v-model="form" label="Message" placeholder="Your message" rows="5" type="textarea" />
    </div>
    <div class="modal_actions">
      <Button secondary-button text="Cancel" @click="cancel"></Button>
      <Button text="Send Proof" type="submit"></Button>
    </div>
  </form>
</template>

<script setup>
import { ref, toRefs } from "vue";
import Button from "@/components/UI/Form/Button/Button.vue";
import { useModal } from "@/components/UI/Modal/useModal";
import { sendPreviewApi } from "@/api/order";
import { useOrderActions } from "@/hooks/store/useOrderStore";
import { useAppStoreActions } from "@/hooks/store/useApp";
import { checkNextOrder } from "@/views/VerificationOrder/VerificationOrder.utils";
import { useRouter } from "vue-router";
import useToast from "@/hooks/useToast";

const { useLoadVerification } = useOrderActions();
const { setLoading } = useAppStoreActions();
const { close } = useModal();
const { openToast } = useToast();
const router = useRouter();

const props = defineProps({
  id: Number || String,
  selected: Array,
});

const { id, selected } = toRefs(props);

const cancel = () => {
  close(null);
};

const form = ref("");

const save = () => {
  close(null);
  setLoading(true);

  sendPreviewApi(id.value, {
    content: form.value,
    preview: selected.value,
  })
    .then(() => {
      openToast("Preview sent successfully");
    })
    .then(() => {
      return checkNextOrder({
        callback: useLoadVerification,
        setLoading,
        router,
      });
    });
};
</script>

<style lang="scss" scoped></style>
