/**
 * Sets a query parameter in history.
 *
 * @private
 */
export const setQueryParam = ({ history, location, parameter, replace, value }) => {
  const { search } = location;
  const queryParams = new URLSearchParams(search);

  if (value) {
    queryParams.set(parameter, value);
  } else {
    queryParams.delete(parameter);
  }
  const destination = `${location.pathname}?${queryParams.toString()}`;

  if (replace) {
    history.replace(destination);
  } else {
    history.push(destination);
  }
};
