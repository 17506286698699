<template>
  <form action="false" @submit.prevent="handleSubmit">
    <div class="modal_inputs">
      <FormKit
        id="flag"
        v-model="form"
        label="Highlight important things"
        placeholder="Message"
        rows="5"
        type="textarea"
      />
    </div>
    <div class="modal_actions">
      <Button secondary-button text="Cancel" type="button" @click="handleCancel" />
      <Button text="Save" type="submit" />
    </div>
  </form>
</template>

<script setup>
import { ref } from "vue";
import { flagOrderApi } from "@/api/orders";
import Button from "@/components/UI/Form/Button/Button.vue";
import { useModal } from "@/components/UI/Modal/useModal";

const props = defineProps({
  id: String || Number,
});

const form = ref("");
const { close } = useModal();

const handleCancel = () => {
  close(null);
};
const handleSubmit = () => {
  flagOrderApi(props.id, { flag: form.value });
  close(null);
};
</script>
