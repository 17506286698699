<template>
  <path
    d="M0 12C0 5.37259 5.37259 0 12 0C18.6275 0 24 5.37259 24 12C24 18.6275 18.6275 24 12 24C5.37259 24 0 18.6275 0 12Z"
    fill="#EE8115"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.125 4.5C2.84886 4.5 2.625 4.72386 2.625 5V19C2.625 19.2762 2.84886 19.5 3.125 19.5H20.875C21.1512 19.5 21.375 19.2762 21.375 19V5C21.375 4.72386 21.1512 4.5 20.875 4.5H3.125ZM19.5001 6.375H4.50008V16.5912C4.50008 16.5434 4.51722 16.4971 4.54839 16.4608L9.047 11.2261C9.12287 11.1378 9.2578 11.1327 9.34011 11.215L12.7962 14.6711C12.8743 14.7492 13.0009 14.7492 13.079 14.6711L14.6751 13.075C14.7516 12.9985 14.8752 12.9967 14.9539 13.0711L19.4375 17.3102C19.4775 17.3479 19.5001 17.4005 19.5001 17.4555V6.375ZM15.7501 8.25001C14.7145 8.25001 13.8751 9.08948 13.8751 10.125C13.8751 11.1605 14.7145 12 15.7501 12C16.7856 12 17.6251 11.1605 17.6251 10.125C17.6251 9.08948 16.7856 8.25001 15.7501 8.25001Z"
    fill="white"
  />
</template>
