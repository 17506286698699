import sortBy from "@/utils/sortBy";

export const setSelectedItem = (data) => {
  const { list } = data;
  const { selectedItem } = data;

  if (list.indexOf(selectedItem) !== -1) {
    return list.filter((item) => item !== selectedItem);
  }

  list.push(selectedItem);
  return list;
};

export const changeInventoriesList = (data) => {
  const { selectedArray, array, callback } = data;
  if (selectedArray.length === 0) {
    return sortBy(callback(array), "count");
  }
  return sortBy(callback(array.filter((item) => selectedArray.includes(item.id))), "count");
};

export const changeSellersList = (data) => {
  const { selectedArray, array, callback } = data;
  if (selectedArray.length === 0) {
    return sortBy(callback(array), "count");
  }
  return sortBy(
    callback(
      array
        .filter((seller) =>
          seller.inventories
            .map((inventory) => inventory.id)
            .some((inv) => selectedArray.includes(inv))
        )
        .map((seller) => ({
          ...seller,
          count: seller.inventories.reduce((acc, curr) => {
            if (selectedArray.includes(curr.id)) {
              return acc + curr.count;
            }
            return acc;
          }, 0),
        }))
    ),
    "count"
  );
};
