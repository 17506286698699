import { getOrdersApi } from "@/api/orders";
import { storeToRefs } from "pinia";
import { useOrdersStore } from "@/store/orders";

export const useOrdersActions = () => {
  const { loading, items, pagination } = storeToRefs(useOrdersStore());
  const loadOrders = (params) => {
    loading.value = true;
    return getOrdersApi(params)
      .then((response) => {
        const { pagination: _pagination, orders, ordersGroupCount, shippingLabels } = response.data;
        pagination.value = _pagination;
        items.value = orders.map((order) => ({
          ...order,
          count: ordersGroupCount[order.order_group_id],
          shippingLabels: shippingLabels[order.id],
        }));
        return response;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        loading.value = false;
      });
  };
  return { loadOrders, loading, items, pagination };
};
