import { storeToRefs } from "pinia";
import {
  getCubbyStatusesById,
  getOrderDetailsApi,
  getOrderOptimizeApi,
  getOrderOptionsApi,
  getVerificationApi,
  resendByEmail,
} from "@/api/order";
import { useOrderStore } from "@/store/order";

export const useOrderActions = () => {
  const { loading, item, optimize, options, cubby, verification } = storeToRefs(useOrderStore());
  const useLoadOrder = (params) => {
    loading.value = true;
    getOrderDetailsApi(params)
      .then((response) => {
        item.value = response.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        loading.value = false;
      });
  };
  const useLoadOptimize = (params) => {
    getOrderOptimizeApi(params)
      .then((response) => {
        optimize.value = response.data;
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
  const useLoadOptions = (params) => {
    getOrderOptionsApi(params)
      .then((response) => {
        options.value = response.data;
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
  const useLoadCubby = (params) => {
    getCubbyStatusesById(params)
      .then((response) => {
        cubby.value = response.data;
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  const useLoadVerification = (params) => {
    return getVerificationApi(params)
      .then((response) => {
        verification.value = response.data;
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  const useResendEmail = (params) => {
    return resendByEmail(params).catch((error) => {
      throw error;
    });
  };

  const useResetOrder = () => {
    item.value = {};
    optimize.value = {};
    options.value = {};
    cubby.value = {};
  };

  return {
    useLoadOrder,
    useLoadOptimize,
    useLoadOptions,
    useResetOrder,
    useLoadCubby,
    useLoadVerification,
    useResendEmail,
    loading,
    item,
    optimize,
    options,
    cubby,
    verification,
  };
};
