<template>
  <form action="false" @submit.prevent="handleSubmit">
    <div class="modal_inputs">
      <FormKit
        id="flag"
        v-model="form"
        label="Add Instruction"
        placeholder="Message"
        rows="5"
        type="textarea"
      />
    </div>
    <div class="modal_actions">
      <Button secondary-button text="Cancel" type="button" @click="handleCancel" />
      <Button text="Save" type="submit" />
    </div>
  </form>
</template>

<script setup>
import { ref, toRefs } from "vue";
import Button from "@/components/UI/Form/Button/Button.vue";
import { useModal } from "@/components/UI/Modal/useModal";
import { addInstructionApi } from "@/api/order";
import { useAppStoreActions } from "@/hooks/store/useApp";
import { useOrdersActions } from "@/hooks/store/useOrdersStore";
import useToast from "@/hooks/useToast";
import { useRoute } from "vue-router";

const props = defineProps({
  id: String || Number,
  note: String,
});

const { loadOrders } = useOrdersActions();

const { id, note } = toRefs(props);
const form = ref(note.value);
const { setLoading } = useAppStoreActions();

const { close } = useModal();
const { openToast } = useToast();

const handleCancel = () => {
  close(null);
};

const route = useRoute();
const handleSubmit = () => {
  setLoading(true);
  const params = new URLSearchParams({
    ...route.query,
  });
  addInstructionApi(id.value, { notes: form.value })
    .then(() => {
      loadOrders(params);
      openToast("Instruction added successfully");
    })
    .finally(() => {
      setLoading(false);
    });
  close(null);
};
</script>
