<template>
  <g clip-path="url(#clip0_2580_16502)">
    <path
      d="M11.834 14.8404V16.7502H13.7434L19.3751 11.1178L17.4656 9.20803L11.834 14.8404ZM13.3208 15.7316H12.8524V15.2631L17.4656 10.6492L17.9341 11.1178L13.3208 15.7316ZM20.8517 8.92285L19.6602 7.73118C19.5584 7.62933 19.4311 7.5835 19.2987 7.5835C19.1663 7.5835 19.039 7.63442 18.9423 7.73118L18.0104 8.66313L19.9199 10.5728L20.8517 9.6409C21.0503 9.44229 21.0503 9.12146 20.8517 8.92285Z"
      fill="black"
    />
    <path
      d="M13.3208 15.7316H12.8524V15.2631L17.4656 10.6492L17.9341 11.1178L13.3208 15.7316Z"
      fill="black"
    />
    <path
      clip-rule="evenodd"
      d="M4.50033 3H10.917L16.417 8.5V8.56699L14.5837 10.4003V9.41667H10.0003V4.83333H4.50033V17.6667H14.5837V17.2861L16.417 15.4212V17.6667C16.417 18.675 15.592 19.5 14.5837 19.5H4.49116C3.48283 19.5 2.66699 18.675 2.66699 17.6667L2.67616 4.83333C2.67616 3.825 3.49199 3 4.50033 3Z"
      fill="black"
      fill-rule="evenodd"
    />
    <path
      d="M7.25065 16.7498C7.75691 16.7498 8.16732 16.3394 8.16732 15.8332C8.16732 15.3269 7.75691 14.9165 7.25065 14.9165C6.74439 14.9165 6.33398 15.3269 6.33398 15.8332C6.33398 16.3394 6.74439 16.7498 7.25065 16.7498Z"
      fill="#C52A28"
    />
    <path d="M6.33398 9.4165H8.16732V13.9998H6.33398V9.4165Z" fill="#C52A28" />
  </g>
  <defs>
    <clipPath id="clip0_2580_16502">
      <rect fill="white" height="24" width="24" />
    </clipPath>
  </defs>
</template>
