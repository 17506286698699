<template>
  <path
    d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
    fill="#C52A28"
  />
  <path
    d="M12 6L7.90909 9.435V8.25H6.54545V10.5825L4.5 12.3L5.325 13.4925L6.54545 12.465V19.5H11.3182V15H12.6818V19.5H17.4545V12.465L18.675 13.485L19.5 12.3L12 6ZM10.6364 4.5C10.6364 5.745 9.72273 6.75 8.59091 6.75C8.21591 6.75 7.90909 7.0875 7.90909 7.5H6.54545C6.54545 6.255 7.45909 5.25 8.59091 5.25C8.96591 5.25 9.27273 4.9125 9.27273 4.5H10.6364Z"
    fill="white"
  />
</template>
