import { ref, onMounted } from "vue";
import { prefillState } from "@/utils/prefillState";
import { useModal } from "@/components/UI/Modal/useModal";
import { useOptionsStoreActions } from "@/hooks/store/useOptionsStore";
import { useUserStoreActions } from "@/hooks/store/useUserStore";
import ROLES from "@/consts/userRoles";
import { useProductionStoreActions } from "@/hooks/store/useProductionStore";
import { INITIAL_FILTERS, EXTRA_PRINTER_ID, PRINTER_ID } from "./Assign.consts";

export const useAssignDialog = (onSubmit) => {
  const { loadLasersOptions, lasersOptions } = useOptionsStoreActions();
  const { currentInventories, currentSellers } = useProductionStoreActions();
  const { close } = useModal();
  const { profile } = useUserStoreActions();
  const loading = ref(true);
  const permitted = ref(false);

  const initialFilters = prefillState(INITIAL_FILTERS);
  const formData = ref({});
  formData.value = initialFilters;

  const handleSubmitAssign = () => {
    onSubmit(formData.value);
  };

  const handleCancel = () => {
    close();
  };

  onMounted(() => {
    permitted.value =
      profile.value.group_id === ROLES.MANAGERS.id ||
      profile.value.group_id === ROLES.ADMINISTRATORS.id;
    loadLasersOptions().then(() => {
      formData.value = {
        ...formData.value,
        [EXTRA_PRINTER_ID]: [null, null],
      };
      if (profile.value.group_id === ROLES.LASER_PRINTERS.id) {
        formData.value[PRINTER_ID] = lasersOptions.value[0].value;
      }
      if (currentInventories.value.length) {
        formData.value.inventory_id = currentInventories.value;
      }
      if (currentSellers.value.length) {
        formData.value.seller_id = currentSellers.value;
      }
      loading.value = false;
    });
  });

  return {
    formData,
    loading,
    permitted,
    lasersOptions,
    handleSubmitAssign,
    handleCancel,
  };
};
