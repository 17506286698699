<template>
  <form @submit.prevent="handleSubmit">
    <div class="modal_inputs">
      <FormKit v-model="formData.laser_name" label="Laser Operator Name" />
      <FormKit v-model="formData.trolly" label="Trolly #" />
    </div>
    <div class="modal_actions">
      <Button secondaryButton text="Cancel" @click="handleCancel" />
      <Button text="Approve" type="submit" />
    </div>
  </form>
</template>

<script setup>
import { ref } from "vue";
import Button from "@/components/UI/Form/Button/Button.vue";
import { laserOrderComplete } from "@/api/laserOrder";
import { useModal } from "@/components/UI/Modal/useModal";
import { useAppStoreActions } from "@/hooks/store/useApp";
import { useLaserOrderActions } from "@/hooks/store/useLaserOrder";
import useToast from "@/hooks/useToast";

const { setLoading } = useAppStoreActions();
const { loadLaserOrder } = useLaserOrderActions();
const { close } = useModal();
const { openToast } = useToast();

const props = defineProps({
  id: String || Number,
  action: {
    type: Function,
    default: () => Promise.resolve(),
  },
});

const INITIAL_FORM_DATA = {
  laser_name: "",
  trolly: "",
};

const formData = ref(INITIAL_FORM_DATA);

const handleCancel = () => {
  close(null);
};

const handleSubmit = () => {
  close(null);
  props
    .action()
    .then(() => {
      laserOrderComplete(props.id, formData.value);
    })
    .then(() => {
      openToast("Laser order completed");
      loadLaserOrder(props.id);
    })
    .catch(() => {
      openToast("Laser order failed to complete");
    })
    .finally(() => {
      setLoading(false);
    });
};
</script>
