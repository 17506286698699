// @ts-check
import { defineStore } from "pinia";

export const useOrderStore = defineStore({
  id: "order",
  state: () => ({
    item: {},
    optimize: {},
    options: {},
    loading: false,
    cubby: {},
    verification: {},
  }),
});
