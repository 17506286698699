import request from "@/utils/request";

export const getLaserOrderApi = (id) =>
  request({
    url: `/api/V2/laser-orders/${id}`,
    method: "get",
  });
export const laserOrderComplete = (id, data) =>
  request({
    url: `/api/V2/laser-orders/complete/${id}`,
    method: "post",
    data,
  });
export const laserOrderPass = (id) =>
  request({
    url: `/api/V2/laser-orders/${id}/pass`,
    method: "post",
  });
export const laserOrderHold = (id, data) =>
  request({
    url: `/api/V2/laser-orders/${id}/hold`,
    method: "post",
    data,
  });
export const laserOrderRedo = (data) =>
  request({
    url: "/api/V2/laser-orders/redo",
    method: "post",
    data,
  });
export const laserOrderDownload = (data) =>
  request({
    url: "/api/V2/laser-orders/download",
    method: "post",
    data,
  });
export const laserOrderDelete = (id) =>
  request({
    url: `/api/V2/laser-orders/${id}`,
    method: "delete",
  });
export const laserOrderResort = (id) =>
  request({
    url: `/api/V2/laser-orders/resort/${id}`,
    method: "get",
  });
