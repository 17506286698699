<template>
  <form actions="false" @submit.prevent="handleSubmitChangeDueDate">
    <div class="modal_inputs">
      <FormKit v-model="currentDueDate" type="date" />
    </div>
    <div class="modal_actions">
      <Button secondary-button text="Cancel" @click="handleCancel"></Button>
      <Button text="Change Due Date" type="submit" />
    </div>
  </form>
</template>

<script setup>
import { ref } from "vue";
import { editDueDateApi } from "@/api/binning";
import Button from "@/components/UI/Form/Button/Button.vue";
import { useModal } from "@/components/UI/Modal/useModal";
import { useBinningStoreActions } from "@/hooks/store/useBinningStore";
import useToast from "@/hooks/useToast";

const props = defineProps({
  id: {
    type: String,
  },
  due: {
    type: String,
  },
});

const currentDueDate = ref(props.due);
const { loadBinnings } = useBinningStoreActions();
const { close } = useModal();
const { openToast } = useToast();

const handleSubmitChangeDueDate = () => {
  editDueDateApi({
    id: props.id,
    due: currentDueDate.value,
  })
    .catch((error) => {
      openToast(error.message);
    })
    .finally(() => {
      close(null);
      loadBinnings();
    });
};

const handleCancel = () => {
  close(null);
};
</script>

<style lang="scss" scoped></style>
