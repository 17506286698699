<template>
  <form action="false" @submit.prevent="create">
    <div class="modal_inputs">
      <FormKit
        label="Laser"
        :closeOnSelect="false"
        type="select"
        name="template"
        :classes="{
          containerActive: 'ring ring-hqPurple ring-opacity-30',
          optionSelected: 'bg-hqPurple text-white',
          optionSelectedPointed: 'bg-hqPurple text-white',
        }"
        placeholder="Laser Name"
        :options="lasersOptions"
        v-model="spot.printer_id"
      />
      <FormKit label="Q-ty" name="qty" type="number" placeholder="QTY" v-model.number="spot.spot" />
    </div>
    <div class="modal_actions">
      <Button secondaryButton text="Cancel" @click="cancel" />
      <Button text="Add Laser" type="submit" />
    </div>
  </form>
</template>
<script setup>
import Button from "@/components/UI/Form/Button/Button.vue";
import { useNewSpotDialog } from "@/components/UI/Modal/Bodies/NewSpot/NewSpot.hook";

const { lasersOptions, spot, create, cancel } = useNewSpotDialog();
</script>
