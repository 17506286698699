import { getCorrectValueFromSearch } from "@/utils/getCorrectValueFromSearch";

/*
  This function is used to get the state from the search query.
  It takes the search query and the filter keys as arguments.
  It returns the state object.
 */
export const getStateFromSearch = (search, filterKeys) => {
  const params = search;
  delete params.page;
  const paramKeys = Object.entries(params);
  const filterState = {};

  filterKeys.forEach(({ key, type }) => {
    paramKeys.forEach(([k, v]) => {
      if (k.includes(key) && k.includes("[")) {
        filterState[key] = [...(filterState[key] || []), v];
      }
      if (k === key) {
        filterState[key] = getCorrectValueFromSearch(v, type);
      }
    });
  });
  return filterState;
};
