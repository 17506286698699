export const ARTISTS_TYPES = [
  { label: "Laser Etching", value: "redo" },
  { label: "Toronto", value: "local" },
  { label: "3D artist", value: "3d" },
];

export const ARTISTS_TYPES_VERIFICATION = [
  { label: "Toronto", value: "local" },
  { label: "3D artist", value: "3d" },
];
