<template>
  <div class="bg-white shadow-login-form rounded px-12 pt-6 pb-8 mb-4">
    <form @submit.prevent="onSubmit">
      <div class="mb-4">
        <FormKit v-model="username" label="Username" placeholder="Username" type="text" />
      </div>
      <div class="mb-6">
        <FormKit v-model="password" label="Password" placeholder="Password" type="password" />
      </div>
      <Button text="Sign In" type="submit"></Button>
    </form>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { ref } from "vue";
import { useAppStoreActions } from "@/hooks/store/useApp";
import { useUserStoreActions } from "@/hooks/store/useUserStore";
import Button from "@/components/UI/Form/Button/Button.vue";
import ROLES from "@/consts/userRoles";

const emit = defineEmits(["confirmAuth"]);
const router = useRouter();
const { loadUserDetails, login } = useUserStoreActions();
const { setLoading } = useAppStoreActions();
const username = ref("");
const password = ref("");

const onSubmit = () => {
  setLoading(true, { text: "Logging in..." });
  login({ username: username.value, password: password.value })
    .then((response) => {
      if (response.data.needVerify === false) {
        loadUserDetails().then((res) => {
          switch (res.data.group_id) {
            case ROLES.MANAGERS.id:
              router.push({ path: ROLES.MANAGERS.home_page });
              break;
            case ROLES.ADMINISTRATORS.id:
              router.push({ path: ROLES.ADMINISTRATORS.home_page });
              break;
            case ROLES.LASER_PRINTERS.id:
              router.push({ path: ROLES.LASER_PRINTERS.home_page });
              break;
            default:
              router.push("/crm/orders");
          }
        });
        return;
      }
      emit("confirmAuth", response.data.isProtected, response.data.qrLink);
    })
    .finally(() => setLoading(false));
};
</script>
