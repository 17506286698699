import request from "@/utils/request";

export const getShippingParamsApi = ({ ids, isBinning }) =>
  request({
    url: `/api/V2/shipping/params/${ids}${isBinning ? "?isBinning=isset" : ""}`,
    method: "get",
  });

export const getShippingEstimationsApi = (params, data) =>
  request({
    url: `/api/V2/shipping/estimate-shipping/${params}`,
    method: "post",
    data,
  });

export const setBoxSizeApi = (data) =>
  request({
    url: `/api/V2/shipping/box`,
    method: "post",
    data,
  });

export const shipByUberApi = (data) =>
  request({
    url: `/api/V2/shipping/uber`,
    method: "post",
    data,
  });

export const pickUpApi = (data) =>
  request({
    url: `/api/V2/shipping/pickup`,
    method: "post",
    data,
  });

export const shipApi = (type, data) =>
  request({
    url: `/api/V2/shipping/ship/${type}`,
    method: "post",
    data,
  });

export const isVerifiedOrderApi = (data) =>
  request({
    url: `/api/V2/orders/check`,
    method: "post",
    data,
  });

export const getGatherBinningApi = (data) =>
  request({
    url: `/api/V2/shipping/binning/${data}`,
    method: "get",
  });

export const saveShippingAddress = (id, data) =>
  request({
    url: `/api/V2/orders-group/${id}/shipping-address`,
    method: "put",
    data,
  });

export const getShippedBins = (data) =>
  request({
    url: `/api/V2/shippings-binning?${data}`,
    method: "get",
  });
