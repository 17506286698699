import IconPDF from "./icons/IconPDF.vue";
import IconAccount from "./icons/IconAccount.vue";
import IconAlarmCheck from "./icons/IconAlarmCheck.vue";
import IconArrowDown from "./icons/IconArrowDown.vue";
import IconArrowLess from "./icons/IconArrowLess.vue";
import IconBox from "./icons/IconBox.vue";
import IconCancel from "./icons/IconCancel.vue";
import IconCheckbox from "./icons/IconCheckbox.vue";
import IconClockInOut from "./icons/IconClockInOut.vue";
import IconClose from "./icons/IconClose.vue";
import IconCog from "./icons/IconCog.vue";
import IconDashboard from "./icons/IconDashboard.vue";
import IconDownload from "./icons/IconDownload.vue";
import IconDropDown from "./icons/IconDropDown.vue";
import IconDropUp from "./icons/IconDropUp.vue";
import IconEmail from "./icons/IconEmail.vue";
import IconEndEnhancement from "./icons/IconEndEnhancement.vue";
import IconEqualizer from "./icons/IconEqualizer.vue";
import IconEye from "./icons/IconEye.vue";
import IconFile from "./icons/IconFile.vue";
import IconFire from "./icons/IconFire.vue";
import IconForum from "./icons/IconForum.vue";
import IconLaser from "./icons/IconLaser.vue";
import IconLogo from "./icons/IconLogo.vue";
import IconLogout from "./icons/IconLogout.vue";
import IconBolt from "./icons/IconBolt.vue";
import IconMenu from "./icons/IconMenu.vue";
import IconMoon from "./icons/IconMoon.vue";
import IconOrders from "./icons/IconOrders.vue";
import IconPackage from "./icons/IconPackage.vue";
import IconPlus from "./icons/IconPlus.vue";
import IconProducts from "./icons/IconProducts.vue";
import IconReporting from "./icons/IconReporting.vue";
import IconSellers from "./icons/IconSellers.vue";
import IconSettings from "./icons/IconSettings.vue";
import IconShell from "./icons/IconShell.vue";
import IconSuccess from "./icons/IconSuccess.vue";
import IconTools from "./icons/IconTools.vue";
import IconTruck from "./icons/IconTruck.vue";
import IconWidgets from "./icons/IconWidgets.vue";
import IconError from "./icons/IconError.vue";
import IconPause from "./icons/IconPause.vue";
import IconBackground from "./order-icons/IconBackground.vue";
import IconBus from "./order-icons/IconBus.vue";
import IconExclamation from "./order-icons/IconExclamation.vue";
import IconFlame from "./order-icons/IconFlame.vue";
import IconHome from "./order-icons/IconHome.vue";
import IconNote from "./order-icons/IconNote.vue";
import IconPlane from "./order-icons/IconPlane.vue";
import IconPreview from "./order-icons/IconPreview.vue";
import IconStack from "./order-icons/IconStack.vue";
import IconUSPS from "./order-icons/IconUSPS.vue";
import IconYoutube from "./order-icons/IconYoutube.vue";
import IconChina from "./order-icons/IconChina.vue";
import IconEdit from "./icons/IconEdit.vue";
import IconStatistics from "./icons/IconStatistics.vue";
import IconSuccessCircle from "./icons/IconSuccessCircle.vue";

export const ICONS = {
  account: () => IconAccount,
  alarm: () => IconAlarmCheck,
  "arrow-down": () => IconArrowDown,
  "arrow-less": () => IconArrowLess,
  box: () => IconBox,
  cancel: () => IconCancel,
  check: () => IconCheckbox,
  "clock-in-out": () => IconClockInOut,
  close: () => IconClose,
  cog: () => IconCog,
  dashboard: () => IconDashboard,
  download: () => IconDownload,
  dropdown: () => IconDropDown,
  "drop-up": () => IconDropUp,
  "end-enhancement": () => IconEndEnhancement,
  email: () => IconEmail,
  equalizer: () => IconEqualizer,
  eye: () => IconEye,
  file: () => IconFile,
  fire: () => IconFire,
  forum: () => IconForum,
  laser: () => IconLaser,
  logo: () => IconLogo,
  logout: () => IconLogout,
  menu: () => IconMenu,
  moon: () => IconMoon,
  orders: () => IconOrders,
  package: () => IconPackage,
  plus: () => IconPlus,
  products: () => IconProducts,
  reporting: () => IconReporting,
  sellers: () => IconSellers,
  settings: () => IconSettings,
  shell: () => IconShell,
  success: () => IconSuccess,
  tools: () => IconTools,
  truck: () => IconTruck,
  widgets: () => IconWidgets,
  error: () => IconError,
  pause: () => IconPause,
  picture: () => IconBackground,
  bus: () => IconBus,
  exclamation: () => IconExclamation,
  flame: () => IconFlame,
  home: () => IconHome,
  note: () => IconNote,
  plane: () => IconPlane,
  preview: () => IconPreview,
  stack: () => IconStack,
  usps: () => IconUSPS,
  youtube: () => IconYoutube,
  bolt: () => IconBolt,
  china: () => IconChina,
  pdf: () => IconPDF,
  edit: () => IconEdit,
  "success-circle": () => IconSuccessCircle,
  statistics: () => IconStatistics,
};
