<template>
  <form action="false" @submit.prevent="onSave">
    <div class="modal_inputs">
      <Multiselect
        :closeOnSelect="false"
        label="Inventories"
        mode="tags"
        searchable
        :options="inventoriesOptions"
        v-model="formData['id']"
        :classes="{
          tag: 'bg-hqPurple300 text-hqPurple rounded flex p-1 pl-2 mb-1 mr-1 text-sm',
        }"
      />
    </div>
    <div class="modal_actions">
      <Button secondaryButton text="Clear" @click="onClear" />
      <Button text="Apply" type="submit" />
    </div>
  </form>
</template>
<script setup>
import Multiselect from "@/components/UI/Form/Multiselect/Multiselect.vue";
import Button from "@/components/UI/Form/Button/Button.vue";
import { useInventoriesFilters } from "@/components/UI/Modal/Bodies/InventoriesFilter/InventoriesFilter.hook";

const { inventoriesOptions, formData, onSave, onClear } = useInventoriesFilters();
</script>
