import request from "@/utils/request";

export const getCubbyApi = (data) =>
  request({
    url: `/api/V2/laser-orders/cubby?${data}`,
    method: "get",
  });

export const submitCubby = (data) =>
  request({
    url: "/api/V2/laser-orders/cubby",
    method: "post",
    data,
  });

export const vacateCubby = (data) =>
  request({
    url: "/api/V2/laser-orders/vacate",
    method: "post",
    data,
  });
