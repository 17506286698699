import { compareAsc } from "date-fns";

export default function today(node) {
  if (
    compareAsc(new Date(node.value), Date.now()) === 1 &&
    compareAsc(new Date(node.value), Date.now()) === 0
  ) {
    return true;
  }
  return false;
}
