/*
This function removes duplicates from an array of objects by a given key.

@param {array} arr - array of objects
@param {string} key - key to compare
@return {array} array of objects without duplicates
*/
const removeDuplicatesByKey = (arr, key) =>
  arr.filter((value, index, self) => index === self.findIndex((t) => t[key] === value[key]));

export default removeDuplicatesByKey;
