import { useModalStore } from "@/store/modal";
import { storeToRefs } from "pinia";
import { markRaw } from "vue";

const defaults = {
  role: "dialog",
  dismissible: true,
  dismissOn: ["backdrop", "esc", "close-button"],
  title: "Modal",
  types: ["popup", "drawer", "filter"],
  type: "popup",
  props: null,
};

export const useModal = () => {
  const store = useModalStore();
  const { options, opened } = storeToRefs(store);

  const open = (_options) => {
    options.value = markRaw({ ...defaults, ..._options });
    opened.value = true;
  };

  // eslint-disable-next-line no-shadow, no-unused-vars
  const close = (data, _options = { dismissed: false }) => {
    opened.value = false;
    options.value = data;
  };

  const clear = () => {
    options.value = null;
  };

  const dismiss = () => {
    close(null, { dismissed: true });
  };

  return {
    open,
    close,
    clear,
    dismiss,
    options,
    opened,
  };
};
