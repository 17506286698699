export const reduceInventories = (sellers) => {
  return sellers
    .map((item) => item.inventories)
    .flat(1)
    .reduce((acc, obj) => {
      const existing = acc.find((o) => o.id === obj.id);
      if (existing) {
        existing.count += obj.count;
      } else {
        acc.push({ ...obj });
      }
      return acc;
    }, []);
};
