<template>
  <div class="bg-white shadow-login-form rounded px-12 py-10 mb-4">
    <form v-show="!isProtect" class="flex flex-col gap-4" @submit.prevent="handleSetupCode">
      <Text bold class="text-heading-1-bold">Scan Code</Text>
      <Text class="w-full">Use Google Auth to snap the QR code and Enter "Confirm"</Text>
      <img :src="qrLink" alt="snap-qr-code" class="w-[428px] h-[428px]" />
      <Button full-width text="I am ready to continue" type="submit" />
    </form>
    <form v-show="isProtect" class="flex flex-col gap-4" @submit.prevent="handleVerifyCode">
      <Text bold class="text-heading-1-bold">Enter Code</Text>
      <Text class="w-full">Type a code from your Google Auth app</Text>
      <FormKit v-model="code" label="Code" placeholder="Code" tabindex="-1" type="text" />
      <Button full-width text="Enter code and Sign In" type="submit"></Button>
    </form>
  </div>
</template>

<script setup>
import { ref, toRefs } from "vue";
import Button from "@/components/UI/Form/Button/Button.vue";
import Text from "@/components/UI/Typography/Text.vue";
import { useAppStoreActions } from "@/hooks/store/useApp";
import { useUserStoreActions } from "@/hooks/store/useUserStore";
import ROLES from "@/consts/userRoles";
import { useRouter } from "vue-router";

const router = useRouter();
const { setLoading } = useAppStoreActions();
const { verify, profile } = useUserStoreActions();
const code = ref("");
const props = defineProps({
  isProtect: {
    type: Boolean,
    required: true,
  },
  qrLink: {
    type: String,
    required: true,
  },
});

const { isProtect } = toRefs(props);

const emit = defineEmits(["enterLogin"]);
const handleSetupCode = () => {
  emit("enterLogin");
};

const handleVerifyCode = () => {
  setLoading(true);
  verify({ code: code.value })
    .then(() => {
      switch (profile.value.group_id) {
        case ROLES.MANAGERS.id:
          router.push({ path: ROLES.MANAGERS.home_page });
          break;
        case ROLES.ADMINISTRATORS.id:
          router.push({ path: ROLES.ADMINISTRATORS.home_page });
          break;
        case ROLES.LASER_PRINTERS.id:
          router.push({ path: ROLES.LASER_PRINTERS.home_page });
          break;
        default:
          router.push("/orders");
      }
    })
    .finally(() => setLoading(false));
};
</script>

<style lang="scss" scoped></style>
