<template>
  <form action="false" @submit.prevent="create">
    <div class="modal_inputs">
      <FormKit
        v-model="values['name']"
        input-class="h-10 w-56"
        label="Name a new order status"
        outer-class="m-0"
        placeholder="Status name"
        type="text"
      />
    </div>
    <div class="modal_actions">
      <Button secondaryButton text="Cancel" @click="cancel" />
      <Button text="Create a Status" type="submit" />
    </div>
  </form>
</template>

<script setup>
import Button from "@/components/UI/Form/Button/Button.vue";
import { useNewOrderStatusDialog } from "./NewStatus.hook";

const { create, values, cancel } = useNewOrderStatusDialog();
</script>
