import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { prefillState } from "@/utils/prefillState";
import { getSearchFromState } from "@/utils/getSearchFromState";
import { getStateFromSearch } from "@/utils/getStateFromSearch";
import { getValidFiltersState } from "@/utils/getValidFiltersState";
import { useOptionsStoreActions } from "@/hooks/store/useOptionsStore";
import { useModal } from "@/components/UI/Modal/useModal";
import {
  EXCLUDE_NCL,
  EXCLUDE_RCCL,
  STATUS_FILTER,
  SELLER_FILTER,
  USER_FILTER,
  DATES,
} from "./OrderFilters.consts";

const filters = [
  { key: STATUS_FILTER, init: null, type: "array" },
  { key: SELLER_FILTER, init: null, type: "array" },
  { key: USER_FILTER, init: null, type: "number" },
  { key: DATES, init: null, type: "array" },
  { key: EXCLUDE_NCL, init: false, type: "boolean" },
  { key: EXCLUDE_RCCL, init: false, type: "boolean" },
];

export const useOrderFilters = () => {
  const {
    loadSellersOptions,
    loadOrderStatusOptions,
    loadDesignersOptions,
    sellersOptions,
    orderStatusOptions,
    designersOptions,
  } = useOptionsStoreActions();
  const route = useRoute();
  const router = useRouter();
  const formData = ref(prefillState(filters));
  const { close } = useModal();

  /* eslint-disable no-underscore-dangle */

  onMounted(() => {
    formData.value = { ...formData.value, ...getStateFromSearch(route.query, filters) };
    loadSellersOptions();
    loadOrderStatusOptions();
    loadDesignersOptions();
  });

  const onSave = () => {
    const values = getValidFiltersState({ ...formData.value });
    const nextPath = getSearchFromState(route.query, filters, values);
    close(null);
    router.push({ path: route.path, query: nextPath });
  };

  const onClear = () => {
    formData.value = prefillState(filters);
    const values = getValidFiltersState({ ...formData.value });
    const nextPath = getSearchFromState(route.query, filters, values);
    close(null);
    router.push({ path: route.path, query: nextPath });
  };

  const handleDateChange = (value) => {
    if (value) {
      formData.value[DATES] = value.map((el) => {
        if (el) {
          return `${el.getFullYear()}/${el.getMonth() + 1}/${el.getDate()}`;
        }

        const now = new Date();

        return `${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()}`;
      });
      return;
    }
    formData.value[DATES] = null;
  };

  return {
    formData,
    sellersOptions,
    orderStatusOptions,
    designersOptions,
    onSave,
    onClear,
    handleDateChange,
  };
};
