<template>
  <form action="false" @submit.prevent="handleSubmitVacate">
    <div class="modal_actions">
      <Button secondary-button text="Cancel" @click="handleCancel"></Button>
      <Button text="Send" type="submit" />
    </div>
  </form>
</template>

<script setup>
import { toRefs } from "vue";
import { vacateCubby } from "@/api/cubby";
import Button from "@/components/UI/Form/Button/Button.vue";
import { useModal } from "@/components/UI/Modal/useModal";
import { useAppStoreActions } from "@/hooks/store/useApp";
import { useCubbyStoreActions } from "@/hooks/store/useCubbyStore";
import { useUserStoreActions } from "@/hooks/store/useUserStore";
import useToast from "@/hooks/useToast";

const props = defineProps({
  trolly: String,
  selectedId: Number || String,
});

const { loadCubby } = useCubbyStoreActions();
const { setLoading } = useAppStoreActions();
const { close } = useModal();
const { openToast } = useToast();

const { trolly, selectedId } = toRefs(props);
const { profile } = useUserStoreActions();

const handleSubmitVacate = () => {
  setLoading(true);
  close(null);
  vacateCubby({
    barcode: selectedId.value,
    laser_name: profile.value.username,
    trolly: trolly.value,
  })
    .then((response) => {
      if (response.status === 200) {
        const message = trolly.value
          ? `Cubby vacated successfully and located in trolly #${trolly.value}`
          : "Cubby vacated successfully";
        openToast(message);
        loadCubby();
      }
    })
    .catch((error) => openToast(error.message))
    .finally(() => {
      setLoading(false);
    });
};

const handleCancel = () => {
  close(null);
};
</script>

<style lang="scss" scoped></style>
