<template>
  <div
    class="fixed flex flex-row pl-[115px] gap-2 bottom-[32px] left-0 min-h-[80px] max-h-[460px] w-full pointer-events-none z-50"
    id="chat-container"
  >
    <transition-group name="chat">
      <div
        v-for="{ id, title, minimized } in openedChatIds"
        :key="id"
        :id="`chat-popup-${id}`"
        class="flex flex-col bg-white rounded shadow-chat-popup p-4 max-w-[340px] h-[420px] w-full transition-all duration-300 ease-in-out pointer-events-auto"
        :style="[minimized ? { transform: `translateY(364px)` } : null]"
        tabindex="-1"
        @click.stop
      >
        <div class="mb-2 flex flex-1 justify-between items-center flex-row">
          <div class="flex items-center gap-1">
            <h1 class="text-base leading-5.5 font-bold select-none">
              {{ title }}
            </h1>
            <Loading v-if="reconnectingProcess" />
            <Button
              v-if="!reconnectingProcess"
              class="border-0"
              secondaryButton
              leftIcon="bolt"
              :leftIconColor="connectedToSocketServer ? 'green' : 'red'"
              leftIconViewBox="0 96 960 960"
              @click="reconnect"
              :title="connectedToSocketServer ? 'Connected' : 'Disconnected'"
            />
          </div>

          <div class="flex gap-1">
            <Button
              class="border-0"
              secondaryButton
              :leftIcon="minimized ? 'arrow-less' : 'arrow-down'"
              @click="toggleMinimize(id)"
            />
            <Button
              class="border-0"
              rigthIcon="close"
              rightIconColor="black"
              secondaryButton
              @click="close(id)"
            />
          </div>
        </div>
        <Chat
          :orderId="id"
          @setconnection="setConnection"
          @toggleminimize="toggleMinimize"
          :minimized="minimized"
          ref="chatRef"
        />
      </div>
    </transition-group>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Button from "@/components/UI/Form/Button/Button.vue";
import Chat from "./Chat.vue";
import { useChatPopup } from "./useChatPopup";
import Loading from "../UI/Loading/Loading.vue";

const { close, toggleMinimize, openedChatIds } = useChatPopup();
const chatRef = ref(null);
const reconnectingProcess = ref(false);

const connectedToSocketServer = ref(false);

const setConnection = (state) => {
  connectedToSocketServer.value = state;
};

const reconnect = () => {
  const discon = () =>
    new Promise((resolve) => {
      chatRef.value[0].disconnect();
      reconnectingProcess.value = true;
      setTimeout(() => {
        resolve();
      }, 500);
    });
  const con = () =>
    new Promise((resolve) => {
      chatRef.value[0].connect();
      reconnectingProcess.value = false;
      resolve();
    });
  discon().then(() => con());
};
</script>

<style lang="scss">
.chat-enter-active,
.chat-leave-active {
  transition: all 1s ease;
}
.chat-enter-from,
.chat-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
</style>
