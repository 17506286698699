import request from "@/utils/request";

export const getInventoriesApi = (data) =>
  request({
    url: `/api/V2/inventories?${data}`,
    method: "get",
  });

export const postInventoryApi = (data) =>
  request({
    url: `/api/V2/inventories`,
    method: "post",
    data,
  });

export const putInventoryApi = (id, data) =>
  request({
    url: `/api/V2/inventories/${id}`,
    method: "put",
    data,
  });

export const deleteInventoryApi = (id) =>
  request({
    url: `/api/V2/inventories/${id}`,
    method: "delete",
  });
