import request from "@/utils/request";

export const unreadMessagesApi = () =>
  request({
    url: `/api/V2/chat/messages/unread`,
    method: "get",
  });

export const authInChatApi = (id) =>
  request({
    url: `/api/V2/chat/${id}/authorize`,
    method: "post",
  });

export const messagesChatApi = (id) =>
  request({
    url: `/api/V2/chat/${id}/messages`,
    method: "get",
  });

export const readMessagesChatApi = (id) =>
  request({
    url: `/api/V2/chat/${id}/messages/read`,
    method: "post",
  });

/**
 *
 * @param {*} id orderId
 * @param {string} data.message { message: string }
 * @returns
 */
export const sendMessageChatApi = (id, data) =>
  request({
    url: `/api/V2/chat/${id}/messages/send`,
    method: "post",
    data,
  });
