<template>
  <div class="modal_inputs">
    <Tabs :active="activeTab" :class="['mb-4']" :onChange="changeTab" :tabs="tabs" />
    <Tab :id="tabs[0].id" :class="['w-full']" :value="activeTab">
      <form action="false">
        <FormKit
          label="Name"
          input-class="h-10"
          outer-class="mb-2"
          name="name"
          placeholder="Name"
          v-model="form.name"
        />
        <FormKit
          label="Q-ty"
          input-class="h-10"
          outer-class="mb-2"
          name="quantity"
          placeholder="Quantity"
          type="number"
          v-model="form.quantity"
        />
        <FormKit
          label="Weight"
          input-class="h-10"
          outer-class="mb-2"
          name="weight"
          placeholder="Weight"
          type="number"
          v-model="form.weight"
        />
        <FormKit
          label="Spec"
          input-class="h-10"
          outer-class="mb-2"
          name="spec"
          placeholder="0x0x0"
          v-model="form.spec"
        />
        <FormKit
          label="Spot"
          input-class="h-10"
          outer-class="mb-2"
          name="spot"
          placeholder="Spot"
          v-model="form.spot"
        />
        <FormKit
          label="Burn Time"
          input-class="h-10"
          outer-class="mb-2"
          name="burn_time"
          placeholder="Burn Time"
          v-model="form.burn_time"
        />
        <FormKit
          label="Sorting"
          input-class="h-10"
          outer-class="mb-2"
          name="sorting"
          placeholder="Sorting"
          v-model="form.sorting"
        />
        <label for="fileInput">
          Upload New Template
          <input
            type="file"
            @change="handleFileUpload"
            accept=".template"
            name="new-templates"
            class="file:cursor-pointer file:mr-4 file:py-1 file:px-2 file:rounded file:border file file:text-body-1 file:border-hqPurple300 file:text-sm file:font-semibold file:text-hqPurple file:bg-white file:border-solid hover:file:bg-hoverSecondaryButton my-2"
        /></label>
      </form>
    </Tab>
    <Tab :id="tabs[1].id" :value="activeTab">
      <Text bold class="mb-3" text="Add new Laser"></Text>
      <div class="flex w-full gap-2 mb-2">
        <FormKit
          :closeOnSelect="false"
          type="select"
          name="template"
          :classes="{
            containerActive: 'ring ring-hqPurple ring-opacity-30',
            optionSelected: 'bg-hqPurple text-white',
            optionSelectedPointed: 'bg-hqPurple text-white',
            outer: 'w-full',
          }"
          placeholder="Laser Name"
          :options="lasersOptions"
          v-model="selectedLaser"
        />
        <FormKit
          name="qty"
          :classes="{ outer: 'w-28' }"
          placeholder="QTY"
          v-model.number="selectedQty"
        />
        <Button text="Add" @click="handleAdd" />
      </div>
      <div v-if="Object.keys(inventorySpots).length > 0">
        <Text bold class="mb-3" text="Selected Lasers"></Text>
        <table>
          <tbody>
            <tr v-for="(spot, laserId) in inventorySpots" :key="laserId">
              <td class="px-2 py-3 w-full">{{ spot.label }}</td>
              <td class="px-2 py-3 w-40">{{ spot.qty }}</td>
              <td class="px-2 py-3 cursor-pointer">
                <Icon icon="close" size="24" @click="handleRemove(spot.id)" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Tab>
  </div>
  <div class="modal_actions">
    <Button secondaryButton text="Cancel" @click="cancel" />
    <Button text="Next" @click="nextTab" v-if="activeTab === 1" />
    <Button text="Create Inventory" @click="handleSubmit" v-if="activeTab === 2" />
  </div>
</template>
<script setup>
import Button from "@/components/UI/Form/Button/Button.vue";
import Tabs from "@/components/UI/Tabs/Tabs.vue";
import Tab from "@/components/UI/Tabs/Tab.vue";
import Text from "@/components/UI/Typography/Text.vue";
import Icon from "@/components/UI/Iconography/Icon/Icon.vue";
import { useNewInventoryDialog } from "./NewInventory.hook";

const {
  tabs,
  activeTab,
  lasersOptions,
  selectedLaser,
  selectedQty,
  inventorySpots,
  form,
  handleFileUpload,
  handleAdd,
  handleRemove,
  handleSubmit,
  nextTab,
  changeTab,
  cancel,
} = useNewInventoryDialog();
</script>
