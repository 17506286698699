<template>
  <form action="false" @submit.prevent="handleSubmitProcessCheck">
    <div class="modal_inputs">
      <FormKit
        id="processCheckScanner"
        v-model="selected"
        class="w-full"
        delay="1000"
        label="Text Order Group Ids"
        placeholder="Order Group Id"
        type="textarea"
      />
      <div class="grid grid-cols-2 gap-2">
        <div class="flex flex-col gap-2">
          <Text bold text="Order groups in bin" />
          <div
            v-for="item in groupList"
            :key="item"
            :class="{ 'text-green-500': isIncluded(item.id) }"
            class="mb-2 last-of-type:mb-0"
          >
            {{ item.id }}
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <Text bold text="Order groups not found" />
          <div v-for="item in notIncludedGroups" :key="item" class="mb-2 last-of-type:mb-0">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="modal_actions">
      <Button secondary-button text="Cancel" @click="handleCancel"></Button>
      <Button :disabled="!includedOrders" text="Submit" type="submit"></Button>
    </div>
  </form>
</template>

<script setup>
import { computed } from "vue";
import Button from "@/components/UI/Form/Button/Button.vue";
import Text from "@/components/UI/Typography/Text.vue";
import { useProcessCheck } from "./ProcessCheck.hook";

const props = defineProps({
  groups: Array,
});

const groupList = computed(() => props.groups);

const {
  selected,
  notIncludedGroups,
  isIncluded,
  includedOrders,
  handleSubmitProcessCheck,
  handleCancel,
} = useProcessCheck(groupList);
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect-purple {
  --ms-tag-bg: #701739;
  --ms-tag-color: #ffffff;
}

.multiselect {
  --ms-ring-color: rgba(112, 23, 57, 0.1);
}
</style>
