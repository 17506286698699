<template>
  <path
    d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
    fill="#FF7500"
  />
  <path
    d="M9.77143 5.85159C10.5943 4.22539 12.8572 4.39963 13.8857 4.69002C10.1143 7.30354 18 10.4867 18 14.2729C18 17.7576 15.2571 19.1128 14 19.5C15.6457 18.3384 15.6 15.4345 15.2571 14.5633C14.7086 15.028 13.3143 15.7249 12.8571 15.7249C14.7771 13.6341 12.9714 11.7562 11.8285 11.0786C12.0571 11.5626 11.9657 12.9371 9.77143 14.5633C7.57716 16.1895 9.08572 18.532 10 19.5C8.85714 18.8224 6.00013 17.4673 6 15.1441C5.99987 12.8029 8.05715 11.9498 8.05715 11.0786C8.05715 10.3817 6.91429 9.43309 6.34286 9.0459H9.77143C9.42857 8.65871 8.94857 7.47778 9.77143 5.85159Z"
    fill="white"
  />
</template>
